<template>
	<!-- 伦理投票 -->
	<CtmsDataGrid
		class="vote-expand-table"
		:height="height"
		:columns="columns"
		:loading="loading"
		:data="voteData"
		:page="false"
	></CtmsDataGrid>
</template>

<script>
import expandRow from "./TableExpand.vue"

export default {
	name: "VoteTable",
	props: ["voteData", "height", "loading"],
	data() {
		return {
			columns: [
				{
					title: "投票意见",
					key: "result"
				},
				{
					title: "票数",
					key: "voteNum"
				},
				{
					title: "投票人",
					key: "name"
				},
				{
					title: "批注数量",
					key: "remarkNum"
				},
				{
					type: "expand",
					titile: "查看批注",
					width: 80,
					render: (h, params) =>
						h(expandRow, {
							props: {
								row: params.row
							}
						})
				}
			]
		}
	},
	methods: {}
}
</script>

<style lang="less">
.vote-expand-table {
	.ivu-table:before {
		height: 0;
	}
	td.ivu-table-expanded-cell {
		padding: 10px 30px;
	}
}
</style>
