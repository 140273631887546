<template>
	<Modal
		class="approval-modal"
		:value="visible"
		width="500"
		:scrollable="true"
		:mask-closable="false"
		:styles="{ top: '20px' }"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<FormItem label="用户版本" prop="newVersion">
				<Input v-model="formData.newVersion" placeholder="请输入用户版本" />
			</FormItem>
			<FormItem label="版本日期">
				<DatePicker
					type="date"
					placeholder="请选择版本日期"
					style="width: 100%"
					v-model="formData.versionTime"
					format="yyyy-MM-dd"
				>
				</DatePicker>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" :loading="loading" @click="onOk">确定</Button>
		</div>
	</Modal>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"

export default {
	name: "VersionEdit",
	props: ["visible", "versionEditObj"],
	data() {
		return {
			loading: false,
			title: "",
			formData: {},
			rules: {
				versionTime: {
					required: true,
					type: "date",
					message: "版本日期不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields()
				return
			}
			this.title = "版本修改"
			this.formData.newVersion = this.versionEditObj.version
			this.formData.versionTime =
				!this.versionEditObj.versionTime ||
				this.versionEditObj.versionTime === "--"
					? new Date()
					: new Date(this.versionEditObj.versionTime)
		}
	},
	computed: {
		...mapState("config", ["IS_ONLY_PDF"])
	},
	methods: {
		onCancel() {
			this.$emit("update:visible", false)
		},
		onOk() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					this.$emit("onOk", {
						...this.versionEditObj,
						version: this.formData.newVersion,
						versionTime: this.formData.versionTime
							? moment(this.formData.versionTime).format("YYYY-MM-DD")
							: ""
					})
				}
			})
		}
	}
}
</script>

<style scoped>
.list {
	padding: 5px;
	font-size: 13px;
}
.list .ivu-icon {
	vertical-align: middle;
	margin-top: -3px;
}
.list .success {
	color: #19be6b;
	margin-left: 5px;
}
.list .error {
	color: #ed4014;
	margin-left: 5px;
}
</style>
