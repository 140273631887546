<template>
	<div class="process-history-wrap">
		<Spin fix v-if="loading"></Spin>
		<div
			v-if="!historyList.length"
			style="text-align: center; height: 200px; line-height: 200px"
		>
			<span>暂无记录</span>
		</div>
		<Collapse
			accordion
			v-else
			@on-change="val => getHistoryForm(val)"
			v-model="activeIndex"
		>
			<Panel
				:name="'history_' + index"
				:key="'history_' + index"
				v-for="(item, index) in historyList"
				:class="{
					currentNode: item.status === 1,
					'nav-link': item.canOpen === 0
				}"
			>
				<span class="task-name" :title="item.taskName"
					>{{ item.taskName }}：</span
				>
				<span>{{ item.dealedTime }}</span>
				<span style="margin-left: 10px">{{ item.dealedBy }}</span>
				<!--<span style="margin-left: 10px">{{item.result}}</span>-->
				<div slot="content" style="position: relative; padding: 0 24px">
					<Spin v-if="formLoading" class="panel-spin"></Spin>
					<div class="history-remark">审批意见：{{ item.remark || "--" }}</div>
					<!--批注内容-->
					<div
						class="upload-file-wrapper"
						v-if="item.annotations && item.annotations.length"
						style="margin-top: 10px; width: 725px"
					>
						<h2 class="collapse-block">批注内容</h2>
						<CtmsDataGrid
							:tooltip-theme="'light'"
							:columns="noteColumns"
							:data="item.annotations"
							:page="false"
							:showIndex="false"
						>
							<div slot-scope="{ row }" slot="annotation">
								<Tooltip
									:content="row.annotation"
									class="tooltip-class"
									theme="light"
									max-width="300"
									:transfer="true"
								>
									<p class="ellipsis-text">{{ row.annotation }}</p>
								</Tooltip>
							</div>
						</CtmsDataGrid>
						<!--</div>-->
					</div>
					<!--表单-->
					<div
						v-if="
							(item.formList && item.formList.length) ||
							(item.formFiles && item.formFiles.length)
						"
						style="margin-top: 16px"
					>
						<div
							v-for="(directory, directoryIndex) in item.formFiles"
							class="file-content"
							:key="directoryIndex"
						>
							<h3>
								<span
									v-if="directory.directoryName.split('*').length > 1"
									style="color: red; margin-right: 3px"
									>*</span
								>
								{{
									directory.directoryName.split("*").length > 1
										? directory.directoryName.split("*")[1]
										: directory.directoryName
								}}
							</h3>
							<ul v-if="directory.list.length">
								<li
									v-for="(file, fileIndex) in directory.list"
									class="approval-file-list"
									:key="fileIndex"
								>
									<span class="file-name">{{ file.name }}</span>
									<a
										class="file-preview"
										@click="previewFile(directoryIndex, fileIndex)"
										>查看</a
									>
								</li>
							</ul>
							<div style="padding: 0 16px; font-weight: bold" v-else>N/A</div>
						</div>
						<div
							v-for="form in item.formList"
							:key="form.id"
							style="margin-bottom: 10px; border: 1px solid #ddd"
						>
							<h2 class="form-name">{{ form.name }}</h2>
							<designTemplate
								:is-no-height="true"
								:componentList="form.json"
								showType="history"
							/>
						</div>
					</div>
					<!--上传文件-->
					<div v-else-if="!item.remark" style="text-align: center">
						<span>暂无记录</span>
					</div>
					<!--编辑角色-->
					<div v-if="item.changeText" class="history-remark">
						<h2>编辑团队成员：</h2>
						<div
							v-for="role in getTeamRoleUser(item.changeText)"
							:key="role.roleId"
						>
							<h4>{{ role.roleName }}</h4>
							<p>
								修改前：{{ role.before.map(user => user.userName).join(",") }}
							</p>
							<p>
								修改后：{{ role.after.map(user => user.userName).join(",") }}
							</p>
						</div>
					</div>
					<!--工作表-->
					<WorkSheet
						v-if="item.subProcessForms && item.subProcessForms.length"
						:workSheetList="item.subProcessForms"
						@formPrev="formPrev"
						:index="index"
					>
					</WorkSheet>
				</div>
			</Panel>
		</Collapse>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:isFullScreen="true"
			:documentSrc="documentSrc"
			:id="getRandomId"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</div>
</template>

<script>
import api from "@/api/project/process"
import docApi from "@/api/document/docfile"
import benchApi from "@/api/workbench/workbench"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import docType from "@/config/docType"
import WorkSheet from "./WorkSheet.vue"

const { apiGetProcessHistory, apiGetHistoryForm } = api
const { apiGetMyRoles } = benchApi
const { getFileExist } = docApi
export default {
	name: "History",
	props: ["id", "historyId", "projectId"],
	components: {
		DocumentPreview,
		designTemplate,
		WorkSheet
	},
	data() {
		return {
			loading: false,
			activeIndex: "",
			historyList: [],
			formLoading: false,
			documentPreviewVisible: false,
			documentSrc: "",
			formDataListObj: {},
			noteColumns: [
				// 右侧 批注内容列表
				{
					title: "文件名称",
					key: "docfileName",
					minWidth: 120
				},
				{
					title: "文件版本",
					key: "version",
					width: 100
				},
				{
					title: "批注内容",
					key: "annotation",
					slot: "annotation",
					minWidth: 110
				},
				{
					title: "批注人",
					key: "operatorName",
					width: 110
				}
			],
			isSuper: false,
			selfRoles: []
		}
	},
	computed: {
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		}
	},
	mounted() {
		this.getRoles() // 获取自己在当前项目下的角色列表
		this.$nextTick(() => {
			if (this.id) {
				this.activeIndex = "0"
				this.getProcessHistory()
			}
		})
	},
	methods: {
		// 获取自己在当前项目下的角色列表
		getRoles() {
			this.$asyncDo(async () => {
				const res = await apiGetMyRoles({
					projectId: this.projectId
				})
				if (res && res.data) {
					this.isSuper = !!(res.data.isSuper === 1)
					if (!this.isSuper) {
						this.selfRoles = res.data.roles || []
					} else {
						this.selfRoles = []
					}
				}
			})
		},
		// 根据角色权限 获取是否展示表单
		getShowStatus(permsRoles) {
			if (this.isSuper || !permsRoles || !permsRoles.length) return true
			let status = false
			for (let i = 0; i < permsRoles.length; i++) {
				if (this.selfRoles && this.selfRoles.includes(permsRoles[i])) {
					status = true
					break
				}
			}
			return status
		},
		// 获取流程审批历史
		getProcessHistory() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProcessHistory({ id: this.id })
				if (res) {
					this.historyList = res.data || []
					if (this.historyId) {
						this.historyList = this.historyList.filter(
							item => item.id === this.historyId
						)
						this.activeIndex = "history_0"
						this.getHistoryForm(["history_0"], "Emit")
					}
				}
				this.loading = false
			})
		},
		// 根据流程id，获取流程审批记录
		async getHistoryForm(openArr, type) {
			if (openArr.length) {
				// 获取Panel的index，由于没有提供当前点击的panel的api，
				// 所以使用手风琴模式，只能开启一个折叠面板
				const index = openArr[0].split("_")[1]
				;[this.activeIndex] = openArr
				const historyList = Object.assign([], this.historyList)
				if (!historyList[index].formList) {
					this.$asyncDo(async () => {
						this.formLoading = true
						historyList[index].formList = []
						historyList[index].subProcessForms = []
						historyList[index].annotations = []
						const res = await apiGetHistoryForm({
							id: this.historyList[index].id
						})
						if (res) {
							// 获取填写的表单的数据
							if (res.data.formDataList.length) {
								res.data.formDataList.forEach(form => {
									// 去掉流程审批记录，表单权限判断
									// if (this.getShowStatus(form.roles)) {
									Object.assign(form, { json: JSON.parse(form.json) })
									historyList[index].formList.push(form)
									// }
								})
							}
							historyList[index].subProcessForms = res.data.subProcessForms
							historyList[index].annotations = res.data.annotations
							//* 是否可以主动撤回流程，0-不可以，1-可以
							historyList[index].isCanWithdrawal = res.data.isCanWithdrawal
							if (type === "Emit") {
								this.$emit("historyView", res.data.isCanWithdrawal)
							}
							// 获取上传的文件的数据
							const filesKeys = Object.keys(res.data.formFiles)
							if (filesKeys.length) {
								filesKeys.forEach(key => {
									if (!historyList[index].formFiles) {
										historyList[index].formFiles = []
									}
									historyList[index].formFiles.push({
										directoryName: key,
										list: res.data.formFiles[key]
									})
								})
							}
						}
						this.historyList = historyList
						this.formLoading = false
					})
				}
			}
		},
		// 查看文件
		previewFile(fileGroupIndex, fileIndex) {
			const index = this.activeIndex.split("_")[1]
			const file =
				this.historyList[index].formFiles[fileGroupIndex].list[fileIndex]
			this.$asyncDo(async () => {
				const res = await getFileExist(file.id)
				if (res) {
					// 文件存在
					const suffix = file.suffix ? file.suffix.toUpperCase() : ""
					if (docType.previewTypes.indexOf(suffix) > -1) {
						this.documentSrc = `${process.env.VUE_APP_REQUEST_BASE_URL}/project/${file.projectId}/docfile/${file.id}/inline?token=${this.$store.state.user.token}`
						this.documentPreviewVisible = true
					} else {
						const downloadUrl = `${this.$baseUrl}/project/${file.projectId}/docfile/${file.id}/download?token=${this.$store.state.user.token}`
						window.open(downloadUrl)
					}
				}
			})
		},
		// 工作表填写预览
		formPrev(documentSrc) {
			this.documentSrc = documentSrc
			this.documentPreviewVisible = true
		},
		// 获取团队编辑操作明细
		getTeamRoleUser(data) {
			let jsonData = {}
			// 防止后端返回的字符串不正确
			try {
				jsonData = JSON.parse(data)
			} catch (err) {
				jsonData = {}
			}

			return jsonData.data || []
		}
	}
}
</script>

<style lang="less">
.process-history-wrap {
	/*由于spin没有默认样式，因此需要兼容一下*/
	.panel-spin {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		.ivu-spin-main {
			display: inline-block;
		}
	}
	.html-content {
		font-size: 14px;
		width: 725px;
		margin-bottom: 16px;
		padding: 5px;
		border: 1px solid #ccc;
		table {
			width: 100% !important;
			/*table-layout: fixed;*/
		}
	}
	.history-remark {
		font-size: 14px;
	}
	.file-content {
		width: 725px;
		margin: 0 0 16px;
		padding: 5px;
		border: 1px solid #ccc;
		&:last-child {
			margin-bottom: 0;
		}
		.approval-file-list {
			font-size: 14px;
			padding: 0 16px;
			list-style: none;
			.file-name {
				margin-right: 8px;
			}
			.file-delete:hover {
				text-decoration: underline;
			}
		}
	}
	.history-content {
		span {
			vertical-align: middle;
		}
		.task-name {
			display: inline-block;
			max-width: 50%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			margin-right: 10px;
		}
	}
	.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
		height: auto;
	}
	.currentNode .ivu-collapse-header {
		//background: linear-gradient(to right, #fe834b, #fda21e);
		background: #1c2730;
		color: #ffffff;
	}
	.nav-link {
		pointer-events: none;
	}
	/* 超出两行省略*/
	.ellipsis-text {
		width: 100%;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}
.tooltip-class {
	.ivu-tooltip-rel {
		vertical-align: middle;
	}
}
</style>
