<template>
	<div>
		<Spin fix v-if="loading"></Spin>
		<DagreG6Process
			:data="flowList"
			ref="dagreG6"
			:id="type"
			:finishedActs="finishedActs"
			:curTaskId="curTaskId"
		></DagreG6Process>
	</div>
</template>

<script>
import api from "@/api/project/process"
import DagreG6Process from "@/components/BusinessComponents/DagreD3Process/IndexDagre.vue"

const { apiGetProcessView } = api
export default {
	name: "ViewDetail",
	props: ["id", "type"],
	components: {
		DagreG6Process
	},
	data() {
		return {
			viewData: {},
			loading: false,
			flowList: [],
			curTaskId: "",
			finishedActs: []
		}
	},
	mounted() {
		if (this.id) {
			this.getTaskNode()
		}
	},
	watch: {
		id() {
			if (this.id) {
				this.getTaskNode()
			}
		}
	},
	methods: {
		getTaskNode() {
			this.flowList = []
			this.curTaskId = ""
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProcessView({ id: this.id })
				if (res) {
					if (res.data && Object.keys(res.data).length) {
						const flowList = Object.keys(res.data).map(item => res.data[item])
						this.flowList = flowList[0].elements
						this.curTaskId = flowList[0].curTask
						this.finishedActs = flowList[0].finishedActs
					}
				}
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less" scoped>
.process-view-wrap {
	text-align: center;
}
</style>
