<template>
	<Modal
		class="version-update-modal"
		:value="visible"
		width="800"
		:scrollable="true"
		title="更新文件"
		:mask-closable="false"
		:footer-hide="true"
		:styles="{ top: '20px' }"
		@on-cancel="onCancel"
	>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<FormItem label="用户版本" prop="version">
				<Input
					v-model.trim="formData.version"
					:maxlength="30"
					placeholder="请输入用户版本"
				/>
			</FormItem>
			<!--<FormItem-->
			<!--label="版本日期">-->
			<!--<DatePicker-->
			<!--type="date" placeholder="请选择版本日期"-->
			<!--style="width: 100%"-->
			<!--v-model="formData.versionTime"-->
			<!--format="yyyy-MM-dd">-->
			<!--</DatePicker>-->
			<!--</FormItem>-->
			<FormItem style="margin-bottom: 10px">
				<Upload
					:headers="{ token: $store.state.user.token }"
					:before-upload="beforeUpload"
					action=""
					:disabled="loading"
					:accept="IS_ONLY_PDF ? 'application/pdf' : '*'"
					:show-upload-list="true"
				>
					<Button :loading="loading">请选择文件</Button>
					<div
						@click.stop="
							() => {
								return false
							}
						"
						style="
							margin-top: 8px;
							color: #ff9800;
							font-size: 12px;
							line-height: 20px;
						"
					>
						<!--<Icon type="ios-alert" style="font-size: 14px;"/>-->
						<p>1. 文件大小建议在2G以内，最大不超过10G；</p>
						<p>
							2.
							文件名称（含后缀名），长度请限制在英文数字255字符以内，中文85字以内
						</p>
					</div>
				</Upload>
			</FormItem>
		</Form>
		<Row>
			<Col span="24"> 当前文件： </Col>
			<template v-if="fileList.length">
				<Col span="24" v-for="(item, index) in fileList" :key="index">
					<div class="list">
						<Icon type="ios-document" /> {{ item.name }}
						<Icon class="success" type="md-checkmark" />
					</div>
				</Col>
			</template>
			<template v-else>
				<span>{{ fileName }}</span>
			</template>
		</Row>
		<div style="text-align: right">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button
				type="primary"
				:loading="loading"
				:disabled="!isCanSubmit"
				@click="onOk"
				>提交</Button
			>
		</div>
		<div class="version-history">
			<h3>版本记录</h3>
			<CtmsDataGrid
				:height="500"
				:columns="columns"
				:data="listData"
				:page="false"
			>
				<template slot-scope="{ index, row }" slot="name">
					<span @click="selectFile(row)" class="version-file-name">
						{{ row.name }}
					</span>
				</template>
			</CtmsDataGrid>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:isFullScreen="true"
			:documentSrc="documentSrc"
			@onCancel="documentPreviewVisible = false"
			:id="`processVersionDocumentPreview${sourceId}`"
		></DocumentPreview>
	</Modal>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import api from "@/api/document/docfile"
import docType from "@/config/docType"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"

const { getVersionList, getFileExist } = api

export default {
	name: "VersionUpdate",
	components: {
		DocumentPreview
	},
	// entry 为1 表示是资料中心， 2 为流程文档库 3. 合同资料 6 遗传办文档库
	props: [
		"visible",
		"versionUpdateObj",
		"loading",
		"sourceId",
		"projectId",
		"entry"
	],
	data() {
		return {
			title: "",
			formData: {},
			rules: {
				version: {
					required: true,
					message: "版本不能为空",
					trigger: "blur"
				}
			},
			fileList: [],
			columns: [
				{
					title: "文件名称",
					key: "name",
					minWidth: 180,
					slot: "name"
				},
				{
					title: "文件版本",
					key: "version",
					minWidth: 120
				},
				{
					title: "更新人",
					key: "createdBy",
					minWidth: 120
				},
				{
					title: "更新时间",
					key: "versionTime",
					minWidth: 140
				}
			],
			listData: [],
			docType,
			documentPreviewVisible: false,
			documentSrc: "",
			proId: "",
			fileName: "" // 更新前的文件名
		}
	},
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields()
				this.fileList = []
				return
			}
			if (this.visible && this.sourceId) {
				if (this.$route.params.projectId) {
					this.proId = this.$route.params.projectId
				} else {
					this.proId = this.projectId
				}
				this.getVersionList()
			}
			this.title = "更新版本"
			this.$set(this.formData, "version", this.versionUpdateObj.version)
			this.fileName = this.versionUpdateObj.name
		}
	},
	computed: {
		...mapState("config", ["IS_ONLY_PDF"]),
		isCanSubmit() {
			if (this.fileList.length) {
				return true
			}
			if (this.versionUpdateObj.version === this.formData.version) {
				return false
			}
			return true
		}
	},
	methods: {
		strCode(str) {
			// 获取字符串的字节数
			let count = 0 // 初始化字节数递加变量并获取字符串参数的字符个数
			if (str) {
				// 如果存在字符串，则执行
				const len = str.length
				for (let i = 0; i < len; i++) {
					// 遍历字符串，枚举每个字符
					if (str.charCodeAt(i) > 255) {
						// 字符编码大于255，说明是双字节字符(即是中文),linux 汉字要算3个字符
						count += 3 // 则累加3个
					} else {
						count++ // 否则递加一次
					}
				}
				return count // 返回字节数
			}
			return 0 // 如果参数为空，则返回0个
		},
		beforeUpload(file) {
			if (this.strCode(file.name) > 255) {
				this.$Message.error(
					"文件名称过长，请限制在英文数字255字符以内，中文85字以内（含后缀名）!"
				)
				return false
			}
			const size = file.size / 1024
			const filemaxsize = 1024 * 1024 * 10 // 10G
			if (size > filemaxsize) {
				this.$Message.error("附件大小不能大于10G")
				return false
			}
			this.fileList = [file]
			return false
		},
		async getVersionList() {
			const res = await getVersionList({
				sourceId: this.sourceId,
				projectId: this.proId
			})
			if (res) {
				this.listData = res.data
			}
		},
		selectFile(row) {
			this.$asyncDo(async () => {
				const res = await getFileExist(row.id)
				if (res) {
					// 文件存在
					const suffix = row.suffix ? row.suffix.toUpperCase() : ""
					if (this.docType.previewTypes.indexOf(suffix) > -1) {
						this.documentSrc = `${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/inline?token=${this.$store.state.user.token}`
						this.documentPreviewVisible = true
					} else if (
						this.entry === "1" &&
						(!row.allowDownload || !row.suffix)
					) {
						// entry 为1 表示是资料中心， 2 为流程文档库 3. 合同资料 6 遗传办文档库
						this.$Message.error("当前用户没有下载权限")
					} else {
						window.open(
							`${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`
						)
					}
				}
			})
		},
		onOk() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					if (this.fileList.length) {
						const itemC = this.listData.find(
							item => item.name === this.fileList[0].name
						)
						if (itemC) {
							this.$Modal.confirm({
								title: "提示",
								content: `版本历史文件中已包含一个名为"${itemC.name}"的文件，是否进行替换？`,
								onOk: () => {
									this.$Modal.remove()
									this.$emit("onOk", this.fileList[0], {
										...this.versionUpdateObj,
										version: this.formData.version
									})
								}
							})
						} else {
							this.$emit("onOk", this.fileList[0], {
								...this.versionUpdateObj,
								version: this.formData.version
							})
						}
					} else {
						this.$emit("onOk", null, {
							...this.versionUpdateObj,
							version: this.formData.version
						})
					}
				}
			})
		},
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
.version-update-modal {
	.list {
		padding: 5px;
		font-size: 13px;
		.ivu-icon {
			vertical-align: middle;
			margin-top: -3px;
		}
		.success {
			color: #19be6b;
			margin-left: 5px;
		}
		.error {
			color: #ed4014;
			margin-left: 5px;
		}
	}
	.version-file-name {
		cursor: pointer;
		&:hover {
			color: @primary-color;
		}
	}
}
</style>
