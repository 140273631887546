<template>
	<!-- 伦理投票 -->
	<Modal
		class="ethical-vote-modal"
		:value="visible"
		width="1150"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>伦理上会投票</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<Row :style="{ height: gridHeight + 'px' }">
			<Col span="10" v-if="isloaded">
				<div :class="['QR-wrap', { 'QR-full-wrap': fullscreen }]">
					<Spin v-if="uuidLoading" fix></Spin>
					<div id="qrcode"></div>
				</div>
			</Col>
			<Col span="14">
				<div>
					<Button
						type="primary"
						icon="md-refresh"
						@click="initVote(1)"
						class="refresh-btn"
						>刷新投票结果</Button
					>
				</div>
				<vote-table
					:voteData="voteData"
					:height="gridHeight"
					:loading="tableLoading"
				></vote-table>
			</Col>
		</Row>
		<div slot="footer">
			<Button @click="onCancel">取消</Button>
			<Button type="primary" @click="finishVote">完成投票</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import { mapState } from "vuex"
import QRcode from "qrcodejs2"
import expandRow from "./TableExpand.vue"
import VoteTable from "./VoteTable.vue"

const { apiGetVoteCode, apiFinishVote } = api

export default {
	name: "ethicalVote",
	props: ["visible", "toDoId"],
	components: {
		VoteTable
	},
	data() {
		return {
			isloaded: false, // 是否加载完毕
			loading: false,
			uuidLoading: false,
			tableLoading: false,
			fullscreen: false,
			qrcode: "",
			code: "",
			voteId: "",
			status: "", // 投票状态：进行中=1；已结束=2；已取消=3；
			voteData: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 230
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 100
				: this.dataGridHeight
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initVote(0)
			}
		}
	},
	methods: {
		// 初始化
		initVote(type) {
			this.$asyncDo(async () => {
				if (type === 0) {
					this.loading = true
				} else {
					this.tableLoading = true
				}
				const res = await apiGetVoteCode({
					todoId: this.toDoId
				})
				if (res && res.data) {
					// if (type === 0) { // 0 初始化，1 刷新列表
					this.code = res.data.code
					this.status = res.data.status
					this.voteId = res.data.id
					// }
					const newArr = []
					const obj = res.data.resultInfo
					for (const key in obj) {
						if (obj[key].length) {
							const nameArr = obj[key].map(item => item.name)
							const remarkArr = obj[key].map(item => item.remark)
							newArr.push({
								result: key,
								voteNum: obj[key].length,
								name: nameArr.join(","),
								remarkNum: `${remarkArr.length}条`,
								allData: obj[key]
							})
						} else {
							newArr.push({
								result: key,
								voteNum: 0,
								name: "--",
								remarkNum: 0,
								allData: []
							})
						}
					}
					this.voteData = [...newArr]
				}
				this.isloaded = true
				this.$nextTick(() => {
					this.getQRcode()
				})
				if (type === 0) {
					this.loading = false
				} else {
					this.tableLoading = false
				}
			})
		},
		// 生成二维码
		getQRcode() {
			let url = window.location.href.split("#")[0]
			let uuid = ""
			if (this.status === 1) {
				url += "#/vote/mobile/vote"
				uuid = `${url}?code=${this.code}`
			} else {
				url += "#/mobile/voteResult"
				uuid = `${url}?status=${this.status}`
			}
			// console.log('uuid',uuid)
			this.uuidLoading = true
			if (this.qrcode) {
				this.qrcode.clear() // 清除
				this.qrcode.makeCode(uuid)
			} else {
				this.qrcode = new QRcode("qrcode", {
					width: 300,
					height: 300, // 高度
					text: uuid // 二维码内容
				})
			}
			this.uuidLoading = false
		},
		// 完成投票
		finishVote() {
			this.$Modal.confirm({
				title: "完成投票确认",
				content:
					"请确认是否所有人都已完成投票，提交后不可再次发起投票，是否继续提交？",
				loading: true,
				onOk: () => {
					this.$asyncDo(async () => {
						const res = await apiFinishVote(this.voteId)
						if (res) {
							this.$Message.success("投票已完成")
							this.$emit("finishVote")
						}
						this.$Modal.remove()
						this.fullscreen = false
					})
				}
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		}
	}
}
</script>

<style lang="less">
.ethical-vote-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	/* 二维码*/
	.QR-wrap {
		position: relative;
		margin: 120px auto;
		box-sizing: border-box;
		width: 280px;
		height: 280px;
		border-style: solid;
		border-width: 2px;
		border-image-source: linear-gradient(0deg, #fe8b38 0%, #fda21e 100%);
		border-image-slice: 1;

		#qrcode {
			display: inline-block;
		}

		img {
			width: 276px;
			height: 276px;
			border: 15px solid #fff;
		}
		&.QR-full-wrap {
			width: 400px;
			height: 400px;
			margin-top: 15vh;
			img {
				width: 396px;
				height: 396px;
			}
		}
	}
	/*刷新*/
	.refresh-btn {
		margin-bottom: 5px;
		padding: 2px 10px;
		.ivu-icon {
			font-size: 18px;
		}
	}
	.expand-table {
		.ivu-table:before {
			height: 0;
		}
	}
}
</style>
