<template>
	<div
		:id="id"
		style="width: 100%; height: auto; padding: 0; border: 1px solid #bbbbbb"
	></div>
</template>
<script>
import G6 from "@antv/g6"
import dagre from "dagre"
import moment from "moment"
import imgMore from "@/assets/common/more.png"
import { splitStringByNum } from "@/utils/util"

export default {
	props: {
		id: {
			// 唯一id，如果一个页面有相同的id
			// 后面的图不会渲染
			type: String,
			default: `svgCanvas_${new Date().getTime()}`
		},
		data: {
			type: Array,
			required: true,
			default() {
				return []
			}
		},
		curTaskId: {
			type: String,
			default: ""
		},
		finishedActs: {
			type: Array,
			required: false,
			default() {
				return []
			}
		}
	},
	data() {
		return {
			graph: null
		}
	},
	watch: {
		data() {
			if (this.data.length) {
				this.$nextTick(() => {
					this.init()
				})
			}
		}
	},
	methods: {
		init() {
			const data = {
				// 取节点
				nodes: this.getNodes(),
				edges: this.getEdges()
			}
			const toolTipObj = this.initToolTip()
			const g = new dagre.graphlib.Graph()
			g.setDefaultEdgeLabel(() => ({}))
			g.setGraph({
				width: window.innerWidth - 100,
				height: window.innerHeight,
				nodesep: 20, // 可选
				ranksep: 50, // 可选
				controlPoints: true
			})

			dagre.layout(g)

			this.graph = new G6.Graph({
				container: this.id,
				width: window.innerWidth - 50,
				height: window.innerHeight - 330,
				renderer: "svg",
				modes: {
					default: ["drag-canvas", "zoom-canvas"]
				},
				defaultNode: {
					style: {
						fill: "#fff",
						stroke: "#333",
						lineWidth: 1
					},
					labelCfg: {
						style: {
							fill: "#333",
							fontSize: 12,
							textAlign: "center"
						}
					},
					anchorPoints: [
						[0.5, 0],
						[0, 0.5],
						[0.5, 1],
						[1, 0.5]
					]
				},
				defaultEdge: {
					style: {
						endArrow: true,
						lineWidth: 1,
						stroke: "#333"
					}
				}
			})
			this.graph.data(data)
			this.graph.render()
			// this.graph.fitView();
			// 节点上的点击事件
			this.graph.on("node:click", event => {
				const node = event.item
				const { description } = node.get("model")
				const shape = event.target
				if (shape.cfg.className === toolTipObj.INNER_CIRCLE_CLASS) {
					// 如果点击是发生在节点里面的小圆上，显示tooltip
					toolTipObj.showTooltip(description, {
						x: event.canvasX,
						y: event.canvasY
					})
				} else {
					// 否则隐藏tooltip
					toolTipObj.hideTooltip()
				}
			})

			// 点击画布，也隐藏tooltip
			this.graph.on("canvas:click", event => {
				toolTipObj.hideTooltip()
			})
			this.graph.removeBehaviors("click-select", "default")
		},
		initToolTip() {
			let tooltipEl = null
			const INNER_CIRCLE_CLASS = "node-inner-circle"
			// 注册自定义节点
			G6.registerNode(
				"currentNode",
				{
					// 绘制节点
					drawShape: function drawShape(cfg, group) {
						const { shapeType } = this
						const style = this.getShapeStyle(cfg)
						const shape = group.addShape(shapeType, {
							attrs: style
						})
						// 绘制节点里面的小圆。点击这个小圆会显示tooltip
						const innerCircle = group.addShape("image", {
							attrs: {
								x: -cfg.size[0] / 2,
								y: -cfg.size[1] / 2,
								cursor: "pointer",
								img: imgMore,
								width: 20,
								height: 20
							}
						})
						// 设置className属性
						innerCircle.set("className", INNER_CIRCLE_CLASS)
						return shape
					}
				},
				"rect"
			)

			// 在指定的位置显示tooltip
			const showTooltip = (message, position) => {
				if (!tooltipEl) {
					const container = document.getElementById(this.id)
					tooltipEl = document.createElement("div")
					tooltipEl.setAttribute("class", "graph-tooltip")
					container.appendChild(tooltipEl)
				}
				tooltipEl.textContent = message
				// tooltip是相对于画布canvas element绝对定位，所以position的x，y必须是相对于画布的坐标
				tooltipEl.style.left = `${position.x}px`
				tooltipEl.style.top = `${position.y}px`
				tooltipEl.style.display = "block"
			}

			// 隐藏tooltip
			const hideTooltip = () => {
				if (!tooltipEl) {
					return
				}
				tooltipEl.style.display = "none"
			}
			return {
				showTooltip,
				hideTooltip,
				INNER_CIRCLE_CLASS
			}
		},
		getNodes() {
			const nodeStyleMap = {
				end: {
					style: {
						stroke: "#515a6e",
						fill: "#fff"
					},
					labelCfg: {
						style: {
							fill: "#333",
							fontSize: 12
						}
					}
				},
				current: {
					style: {
						stroke: "#f6b557",
						fill: "#f6b557"
					},
					labelCfg: {
						style: {
							fill: "#333",
							fontSize: 12
						}
					}
				}
			}
			const nodes = this.data
				.filter(item => item.type !== "SequenceFlow")
				.map(node => {
					let nodeItem = {}
					const finishItem = this.finishedActs.find(
						item => item.actId === node.id
					)
					let label = node.name
					if (label && label.length > 15) {
						label = splitStringByNum(node.name, 15).join("\n")
					}
					let pos = {}
					let upperLeft = {}
					let lowerRight = {}
					if (node.bounds) {
						upperLeft = { ...JSON.parse(node.bounds).upperLeft }
						lowerRight = { ...JSON.parse(node.bounds).lowerRight }
						pos = {
							x: (upperLeft.x + lowerRight.x) / 2,
							y: (upperLeft.y + lowerRight.y) / 2
						}
					}
					switch (node.type) {
						case "StartEvent":
							nodeItem = {
								...pos,
								id: node.id,
								label: "流程启动",
								type: "ellipse",
								styleType: "end"
							}
							if (finishItem) {
								nodeItem.label += `\n${moment(finishItem.finishedTime).format("YYYY/MM/DD HH:mm:ss")}`
							}
							break
						case "EndEvent":
							nodeItem = {
								...pos,
								id: node.id,
								label: "流程结束",
								type: "ellipse"
							}
							if (finishItem) {
								nodeItem.label += `\n${moment(finishItem.finishedTime).format("YYYY/MM/DD HH:mm:ss")}`
								nodeItem.styleType = "end"
							}
							break
						case "UserTask":
							// label = label.length > 7 ? splitStringByNum(label, 7).join('\n') : label;
							nodeItem = {
								...pos,
								id: node.id,
								label,
								type: "rect",
								description: "",
								size: [lowerRight.x - upperLeft.x, lowerRight.y - upperLeft.y]
							}

							if (node.id === this.curTaskId) {
								nodeItem.styleType = "current"
								nodeItem.description += `审批角色：${node.assignRoleName}`
								nodeItem.type = "currentNode"
							} else if (finishItem) {
								nodeItem.label += `\n${finishItem.dealedUser}\n${moment(finishItem.finishedTime).format("YYYY/MM/DD HH:mm:ss")}`
								// nodeItem.styleType = 'end';
							}
							break
						case "Gateway":
							nodeItem = {
								...pos,
								id: node.id,
								label: "✖️",
								type: "diamond",
								size: [lowerRight.x - upperLeft.x, lowerRight.y - upperLeft.y]
							}
							break
						default:
							break
					}
					return {
						...nodeItem,
						...nodeStyleMap[nodeItem.styleType]
					}
				})
			return nodes
		},
		getEdges() {
			const edges = this.data
				.filter(item => item.type === "SequenceFlow")
				.map(e => {
					const controlPoints = e.dockers
						? JSON.parse(e.dockers).filter(
								(d, index) =>
									index !== 0 && index !== JSON.parse(e.dockers).length - 1
							)
						: []
					let pos = {}
					let upperLeft = {}
					let lowerRight = {}
					if (e.bounds) {
						upperLeft = { ...JSON.parse(e.bounds).upperLeft }
						lowerRight = { ...JSON.parse(e.bounds).lowerRight }
						pos = {
							startPoint: upperLeft,
							endPoint: lowerRight
						}
					}

					return {
						id: e.id,
						label: e.name || "",
						source: e.sourceId,
						target: e.targetId,
						...pos,
						type: controlPoints.length ? "polyline" : "line",
						controlPoints
					}
				})
			return edges
		}
	}
}
</script>
<style>
.graph-tooltip {
	border: 1px solid #e2e2e2;
	border-radius: 4px;
	font-size: 12px;
	color: #545454;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px 8px;
	box-shadow: rgb(174, 174, 174) 0px 0px 10px;
	position: absolute;
	max-width: 200px;
}
</style>
