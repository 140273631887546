<template>
	<div>
		<CtmsDataGrid
			:columns="columns"
			:data="tableData"
			:show-index="false"
			:page="false"
		></CtmsDataGrid>
	</div>
</template>
<script>
export default {
	props: {
		row: Object
	},
	data() {
		return {
			columns: [
				{
					title: "投票人",
					key: "name"
				},
				{
					title: "是否需再次上会",
					key: "isNext",
					render: (h, params) => h("span", {}, params.row.isNext ? "是" : "否")
				},
				{
					title: "投票日期",
					key: "voteDate"
				},
				{
					title: "批注",
					key: "remark"
				}
			]
			// tableData: [],
		}
	},
	computed: {
		tableData() {
			return this.row.allData || []
		}
	}
}
</script>
<style scoped>
.expand-row {
	margin-bottom: 16px;
}
</style>
