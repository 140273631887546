<template>
	<div>
		<h2>多人填写表单</h2>
		<CtmsDataGrid
			style="margin-bottom: 10px"
			v-for="(listData, i) in workSheetList"
			:key="i"
			:columns="columns"
			:data="listData"
			:page="false"
		>
			<template slot-scope="{ row }" slot="fillStatus">
				{{ row.fillStatus === 2 ? "已填写" : "未填写" }}
			</template>
		</CtmsDataGrid>
	</div>
</template>
<script>
export default {
	props: ["workSheetList", "index"],
	data() {
		return {
			columns: [
				{
					title: "表单名称",
					key: "formName",
					minWidth: 150
				},
				{
					title: "姓名",
					key: "assignName",
					minWidth: 120
				},
				{
					title: "状态",
					key: "fillStatus",
					slot: "fillStatus",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					minWidth: 120,
					renderButton: params => [
						{
							label: "查看",
							on: {
								click: () => {
									const { fileId, projectId, procInstId } = params.row
									const formDocSrc = `${this.$baseUrl}/common/form-file/${fileId}/filled?token=${this.$store.state.user.token}&projectId=${projectId}&procInstId=${procInstId}`
									this.$emit("formPrev", formDocSrc)
								}
							}
						}
					]
				}
			]
		}
	}
}
</script>
