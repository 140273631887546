<template>
  <Modal class="approval-modal"
         :value="visible"
         width="1280"
         :fullscreen="fullscreen"
         :scrollable="true"
         :mask-closable="false"
         :styles="{top: fullscreen ? 0 : '20px'}"
         @on-cancel="onCancel">
    <Spin fix
          v-if="loading"></Spin>
    <div slot="header"
         class="modal-header">
      <h2>审批流程</h2>
      <a href="javascript:;"
         class="fullscreen"
         @click="fullscreen = !fullscreen">
        <Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
          <i v-if="!fullscreen"
             class="iconfont icon-fullscreen-fill"></i>
          <i v-else
             class="iconfont icon-fullscreen-exit-fill"></i>
        </Tooltip>
      </a>
    </div>
    <div class="addText">
      <div><b>项目名称：&nbsp;&nbsp;</b>{{projectName}}</div>
      <div class="addTexts"><b>申办方：&nbsp;&nbsp;</b>{{sponsorLinkedName}}</div>
      <div class="addTexts"><b>CRO：&nbsp;&nbsp;</b>{{croLinkedName}}</div>
      <div class="addTexts"><b>主要研究者：&nbsp;&nbsp;</b>{{researcher}}</div>
    </div>
    <div class="todo-approval-wrap"
         ref="todoApproval">
      <Tabs v-model="tabValue"
            :animated="false">
        <!--审批内容-->
        <TabPane label="审批内容"
                 name="form"
                 :index="1"
                 v-if="type === 'toDo' || type === 'done'">
          <!-- 已办事项 展示的审批内容-->
          <template v-if="agencyType === 1">
            <div :style="{height: conHeight + 'px'}"
                 class="history-content">
              <History :id="procInstId"
                       :projectId="proId"
                       @historyView="handleHistory"
                       v-if="visible && tabValue === 'form'"
                       :historyId="toDoId"></History>
            </div>
          </template>
          <template v-else>
            <Row :gutter="16">
              <Col :span="!isCollapse ? 1 : (isRightCollapse ? 10 : 23)"
                   class="collapse-wrap">

                <div :class="['collapse-arrow',{'collapse-after-arrow': !isCollapse}]">
                  <Tooltip :content="isCollapse ? '左侧收起' : '还原'"
                           placement="top">
                    <Icon type="ios-arrow-dropleft"
                          v-if="isCollapse"
                          @click="isCollapse = false;isRightCollapse = true;" />
                    <Icon type="ios-refresh-circle-outline"
                          @click="isRightCollapse = true;isCollapse=true;"
                          v-if="!isCollapse && isRightCollapse" />
                  </Tooltip>
                </div>
                <div>
                  <Button type="primary"
                          style="position:absolute;right:200px;top:13px;z-index:4;"
                          @click="copyText">复制文件名称
                  </Button>
                  <Button type="primary"
                          style="position:absolute;right:80px;top:13px;z-index:4;"
                          @click="downloadNew">下载最新文件
                  </Button>
                </div>

                <template v-if="tabValue === 'form'">
                  <div v-if="visible && isCollapse">
                    <Tabs type="card"
                          v-model="leftTabValue"
                          :animated="false"
                          class="left-card-wrap">
                      <TabPane label="本流程最新文件"
                               name="file"
                               :index="1">
                        <div :style="{height: `${conHeight-60}px`,overflow: 'auto'}">
                          <NewestFileList ref="newFilesComp"
                                          :projectId="proId"
                                          :procInstId="procInstId"
                                          :procInstName="procInstName"
                                          :taskId="taskId"
                                          entry="approval"
                                          @getDownload="getDownloads"
                                          @getNote="getNoteList"
                                          :docType="docType">
                          </NewestFileList>
                        </div>
                      </TabPane>
                      <TabPane label="本流程审批意见"
                               name="remark"
                               :index="2">
                        <CtmsDataGrid :tooltip-theme="'light'"
                                      :columns="remarkColumns"
                                      :data="historyList"
                                      :page="false"
                                      :showIndex="false"
                                      :height="`${conHeight-60}`">
                          <div slot-scope="{row}"
                               slot="remark">
                            <Tooltip :content="row.remark"
                                     theme="light"
                                     max-width="300"
                                     :transfer="true">
                              <p class="ellipsis-text">{{row.remark}}</p>
                            </Tooltip>
                          </div>
                          <div slot-scope="{row}"
                               slot="dealedBy">
                            <Tooltip :content="row.dealedBy"
                                     theme="light"
                                     max-width="300"
                                     :transfer="true">
                              <p class="ellipsis-text">{{row.dealedBy}}</p>
                            </Tooltip>
                          </div>
                        </CtmsDataGrid>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div v-else
                       @click="isCollapse = true"
                       class="collapse-after">
                    <div style="width: 20px; display: inline-block">
                      本流程最新文件 / 本流程审批意见
                      <Tooltip content="点击查看"
                               placement="right">
                        <i class="iconfont icon-click"
                           style="font-size: 25px;color:#f6b557"></i>
                      </Tooltip>
                    </div>
                  </div>
                </template>
                <!-- 点击查看-->
              </Col>
              <Col :span="!isRightCollapse ? 1 : (isCollapse ? 14 : 23)">
                <Card class="approve-card">
                  <div slot="title"
                       :style="{padding: isRightCollapse ? '7px 16px' : '10px 0'}">
                    <div :class="['collapse-arrow','collapse-right-arrow',
                    {'collapse-after-arrow': !isRightCollapse}]">
                      <Tooltip :content="isRightCollapse ? '右侧收起' : '还原'"
                               placement="top">
                        <Icon type="ios-arrow-dropright"
                              v-if="isRightCollapse"
                              @click="isRightCollapse = false;isCollapse = true;" />
                        <!--<Icon type="ios-arrow-dropleft" v-else/>-->
                        <Icon type="ios-refresh-circle-outline"
                              @click="isRightCollapse = true;isCollapse=true;"
                              v-if="isCollapse && !isRightCollapse" />
                      </Tooltip>
                    </div>
                    <Tooltip v-if="isRightCollapse"
                             style="padding-left:40px;"
                             transfer
                             placement="top-start"
                             :max-width="500"
                             :content="taskName">
                      <p class="card-title over">{{taskName}}</p>
                    </Tooltip>
                  </div>
                  <template slot="extra"
                            v-if="isRightCollapse">
                    <Button type="primary"
                            v-if="showVote && status === 1"
                            style="margin-left: 10px"
                            @click="ethicalVoting">伦理投票
                    </Button>
                    <Button v-if="showDocLibrary"
                            v-show="isCollapse"
                            class="see-document"
                            type="primary"
                            @click="onViewDocument">
                      查看最新版本文件
                    </Button>
                    <Button type="primary"
                            style="margin-left: 10px"
                            @click="viewFormList">查看配置表单
                    </Button>
                    <Button @click.prevent="openNewWindow"
                            type="primary"
                            style="margin-left: 10px">
                      查看项目信息
                    </Button>
                  </template>
                  <div v-show="isRightCollapse"
                       :style="{'max-height': (conHeight-80) + 'px', overflow: 'auto', padding: '16px'}">
                    <!--表单，上传文件，编辑角色-->
                    <div :style="{ 'overflow-x': 'hidden', 'overflow-y': 'auto', padding: '8px 5px'}">
                      <template v-if="todoMultiExtend">
                        <div class="upload-file-wrapper">
                          <h2 class="collapse-block">审批角色
                            <span class="collapse-icon"
                                  @click="showRoles = !showRoles">
                            <Icon type="ios-arrow-dropup-circle"
                                  v-if="showRoles"
                                  title="点击折叠" />
                            <Icon type="ios-arrow-dropdown-circle"
                                  v-else
                                  title="点击展开" />
                          </span>
                          </h2>
                          <p class="condition-content"
                             v-if="showRoles">
                            {{todoMultiExtend.roles.map(item => item.name).join('、')}}
                          </p>
                        </div>
                        <div class="upload-file-wrapper">
                          <h2 class="collapse-block">通过条件
                            <span class="collapse-icon"
                                  @click="showConditions = !showConditions">
                            <Icon type="ios-arrow-dropup-circle"
                                  v-if="showConditions"
                                  title="点击折叠" />
                            <Icon type="ios-arrow-dropdown-circle"
                                  v-else
                                  title="点击展开" />
                          </span>
                          </h2>
                          <p class="condition-content"
                             v-if="showConditions">
                            {{multiTypeMap[todoMultiExtend.multiType]}}</p>
                        </div>
                      </template>
                      <!--表單填写, 根据角色显示隐藏表单-->
                      <div
                        v-for="template in templateList"
                        :key="template.id"
                        class="upload-file-wrapper">
                        <h2 class="collapse-block collapse-form">{{template.name}}
                          <span class="collapse-icon"
                                @click="handleCollapse(showForm[`form${template.id}`],template)">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showForm[`form${template.id}`]"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <designTemplate v-show="showForm[`form${template.id}`]"
                                        :is-no-height="true"
                                        :toDoId="toDoId"
                                        :componentList="template.json"
                                        :ref="`designTemplate_${template.id}`"
                                        @func="getfunc"
                                        :funcData="funcData1"
                                        :showType="template.isReadOnly ? 'readonly' : 'approval'">
                        </designTemplate>
                      </div>
                      <!--上传文件-->
                      <div class="upload-file-wrapper"
                           v-if="uploadFileList.length">
                        <h2 class="collapse-block">上传文件<span class="warn">(*号为必填项）</span>
                          <span class="collapse-icon"
                                @click="showUpload = !showUpload">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showUpload"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <div class="file-list-wrapper"
                             v-if="showUpload">
                          <div :key="fileGroupIndex"
                               class="upload-form"
                               v-for="(fileGroup, fileGroupIndex) in uploadFileList">
                            <!--根据角色权限，显示/隐藏 上传文件-->
                            <!--<template v-if="getShowStatus(fileGroup.roles)">-->
                            <h4 class="file-upload-title">
                              <Tooltip placement="bottom-start"
                                       transfer
                                       max-width="300"
                                       :content="fileGroup.fileName">
                                <p class="over">
                                <span class="warn"
                                      v-if="fileGroup.isRequired">*</span>
                                  {{fileGroup.fileName}}：
                                </p>
                              </Tooltip>

                              <Upload class="file-upload-btn"
                                      action="/"
                                      :accept="IS_ONLY_PDF ? 'application/pdf' : '*'"
                                      :before-upload="file => handleUpload(file, fileGroupIndex)"
                                      multiple>
                                <Button icon="md-add">添加上传文件</Button>
                              </Upload>
                            </h4>
                            <CtmsDataGrid :columns="filesColumns"
                                          :data="fileGroup.hisFileDataList"
                                          :page="false"
                                          :showIndex="false">
                              <div slot-scope="{row}"
                                   slot="fileName">
                                <a style="color: #515a6e" title="预览"
                                   @click="handlePreview(row)">{{row.name}}</a>
                              </div>
                              <div slot-scope="{row}"
                                   slot="version">
                                <span>{{row.version}}</span>
                              </div>
                              <template slot-scope="{row}"
                                        slot="uploadStatus">
                              <span :style="`color:${getStatusStr(row.uploadStatus).color}`">
                                {{getStatusStr(row.uploadStatus).text}}
                              </span>
                              </template>
                              <div slot-scope="{row, index}"
                                   slot="actions">
                                <template v-if="row.id && !row.isLock">
                                  <a @click="handleReplace(row, fileGroupIndex, index)">更新</a>
                                  <span class="divider"></span>
                                </template>
                                <!--如果更新了文件，则隐藏删除按钮-->
                                <template v-if="!row.docfileId && !row.isLock">
                                  <a @click="deleteFile(fileGroupIndex, index)">删除</a>
                                </template>
                              </div>
                            </CtmsDataGrid>
                            <!--</template>-->
                          </div>
                        </div>
                      </div>
                      <!--查看文件-->
                      <div class="upload-file-wrapper"
                           v-if="viewFileList.length">
                        <h2 class="collapse-block">查看已上传文件
                          <span class="collapse-icon"
                                @click="showFiles = !showFiles">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showFiles"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <div class="file-list-wrapper"
                             v-if="showFiles">
                          <div :key="fileGroupIndex"
                               class="upload-form"
                               v-for="(fileGroup, fileGroupIndex) in viewFileList">
                            <Tooltip class="file-upload-title"
                                     placement="bottom-start"
                                     v-if="fileGroup.relateDocName"
                                     transfer
                                     max-width="300"
                                     :content="fileGroup.relateDocName">
                              <p class="over">
                                {{fileGroup.relateDocName}}：
                              </p>
                            </Tooltip>
                            <CtmsDataGrid :columns="viewFilesColumns"
                                          :data="fileGroup.hisFileDataList"
                                          :page="false"
                                          :showIndex="false">
                              <div slot-scope="{row}"
                                   slot="fileName">
                                <a style="color: #515a6e" title="预览"
                                   @click="handlePreview(row)">{{row.name}}</a>
                              </div>
                              <div slot-scope="{row}"
                                   slot="version">
                                <span>{{row.version}}</span>
                              </div>
                              <div slot-scope="{row}"
                                   slot="actions">
                                <a @click="handleShowHistoryVersion(row)">版本记录</a>
                              </div>
                            </CtmsDataGrid>
                          </div>
                        </div>
                      </div>
                      <!--伦理上会投票结果展示-->
                      <vote-table v-if="status === 2"
                                  :voteData="voteData"
                                  :height="400"
                                  :loading="tableLoading"></vote-table>

                      <!--选择角色及表单-->
                      <div class="upload-file-wrapper"
                           v-if="teamRoles.length">
                        <h2 class="collapse-block">选择角色及表单
                          <span class="collapse-icon"
                                @click="showRoleForms = !showRoleForms">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showRoleForms"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <template v-if="showRoleForms">
                          <!--编辑角色-->
                          <div v-for="(role, index) in teamRoles"
                               :key="index"
                               class="choose-wrap">
                            <h4 class="file-upload-title">
                            <span class="custom-required"
                                  v-if="chooseFillForms || chooseUserFillForms">*</span>
                              编辑角色「{{role.roleName}}」：
                            </h4>
                            <Select transfer
                                    multiple
                                    filterable
                                    :value="role.userIds"
                                    @on-change="value => handleRoleUserChange(value, index)"
                                    :placeholder="`请选择${role.roleName}`">
                              <Option :value="user.userId"
                                      v-for="user in role.roleUserList"
                                      :key="user.userId">{{user.userName}}
                              </Option>
                            </Select>
                            <!--用户选择不同表单-->
                            <template v-if="chooseUserFillForms">
                              <div class="choose-wrap">
                                <h4 class="file-upload-title">
                                  <span class="custom-required">*</span>
                                  选择填写表单
                                </h4>
                                <div v-for="(user, idx) in role.userList"
                                     :key="idx">
                                  <span>{{user.name}}：</span>
                                  <Select transfer
                                          multiple
                                          filterable
                                          v-model="user.formIds"
                                          @on-change="value => handleRoleFormChange(value, index, idx)"
                                          placeholder="请选择填写表单">
                                    <Option :value="list.id"
                                            v-for="list in subProcessFormList"
                                            :key="list.id">{{list.name}}
                                    </Option>
                                  </Select>
                                </div>
                              </div>
                            </template>
                          </div>
                          <!--用户统一选择表单-->
                          <template v-if="chooseFillForms">
                            <div class="choose-wrap">
                              <h4 class="file-upload-title">
                                <span class="custom-required">*</span>
                                选择填写表单
                              </h4>
                              <Select transfer
                                      multiple
                                      filterable
                                      v-model="fillFormIds"
                                      placeholder="请选择填写表单">
                                <Option :value="list.id"
                                        v-for="list in subProcessFormList"
                                        :key="list.id">{{list.name}}
                                </Option>
                              </Select>
                            </div>
                          </template>
                        </template>
                      </div>
                      <!--批注内容-->
                      <div class="upload-file-wrapper" v-if="noteList.length">
                        <h2 class="collapse-block">批注内容
                          <span class="collapse-icon"
                                @click="showRemarks = !showRemarks">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showRemarks"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <div v-if="showRemarks">
                          <CtmsDataGrid :tooltip-theme="'light'"
                                        :columns="noteColumns"
                                        :data="noteList"
                                        :page="false"
                                        :showIndex="false">
                            <div slot-scope="{row}"
                                 slot="annotation">
                              <Tooltip theme="light"
                                       class="tooltip-class"
                                       max-width="600"
                                       :transfer="true">
                                <p slot="content"
                                   style="max-height: 600px;overflow: auto;padding: 15px;word-break: break-all;line-height:26px;">{{row.annotation}}</p>
                                <p class="ellipsis-text">{{row.annotation}}</p>
                              </Tooltip>
                            </div>
                            <div slot-scope="{row}"
                                 slot="actions">
                              <!--自己可以编辑，删除自己的批注-->
                              <template v-if="row.operatorName === userName">
                                <a @click="handleEditNote(row)">编辑</a>
                                <span class="divider"></span>
                              </template>
                              <template v-if="row.operatorName === userName">
                                <a @click="deleteNote(row)">删除</a>
                              </template>
                            </div>
                          </CtmsDataGrid>
                        </div>
                      </div>
                      <!--审核意见-->
                      <div class="upload-file-wrapper">
                        <h2 class="collapse-block">审核意见：
                          <span class="collapse-icon"
                                @click="showRemark = !showRemark">
                          <Icon type="ios-arrow-dropup-circle"
                                v-if="showRemark"
                                title="点击折叠" />
                          <Icon type="ios-arrow-dropdown-circle"
                                v-else
                                title="点击展开" />
                        </span>
                        </h2>
                        <div class="remark-wrapper"
                             v-if="showRemark">
                          <Input type="textarea"
                                 v-model="remark"
                                 :autosize="{minRows: 2,maxRows: 8}"
                                 maxlength="400"
                                 show-word-limit
                                 placeholder="请输入流程审核意见" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!isRightCollapse"
                       @click="isRightCollapse = true"
                       class="collapse-after">
                    <div style="width: 20px; display: inline-block">
                      {{taskName}}
                      <Tooltip content="点击查看"
                               placement="left">
                        <i class="iconfont icon-click"
                           style="font-size: 25px;color:#f6b557"></i>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </template>
        </TabPane>
        <!--流程审批记录-->
        <TabPane label="流程审批记录"
                 :index="2"
                 name="history">
          <div :style="{height: conHeight + 'px'}"
               class="history-content">
            <History :id="procInstId"
                     :projectId="proId"
                     v-if="visible && tabValue === 'history'"></History>
          </div>
        </TabPane>
        <!--流程图-->
        <TabPane label="流程图"
                 :index="3"
                 name="processView">
          <!--<div :style="{height: conHeight + 'px'}"-->
          <div
            class="history-content"
            style="overflow: hidden"
            id="processView">
            <ViewDetail v-if="tabValue === 'processView'"
                        ref="processView"
                        :type="'svgCanvas_' + type"
                        :id="procInstId"></ViewDetail>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <div slot="footer">
      <Checkbox v-if="type === 'toDo'"
                v-model="sendSysMessage"
                :true-value="1"
                :false-value="0">
        <span class="notify-sty">通知相关人员</span>
      </Checkbox>
      <Checkbox v-if="type === 'toDo'"
                v-model="sendSms"
                :true-value="1"
                :false-value="0">
        <span class="notify-sty">短信通知</span>
      </Checkbox>
      <Checkbox v-if="type === 'toDo' && IS_EMAIL_NOTICE"
                v-model="sendEmail"
                :true-value="1"
                :false-value="0">
        <span class="notify-sty">发送邮件</span>
      </Checkbox>
      <Button style="margin-right: 8px"
              @click="onCancel">{{type === 'toDo' ? '取消' : '关闭'}}</Button>
      <template v-if="type === 'toDo'">
        <Button type="error"
                v-for="button in returnButtonList"
                :key="button.taskKey"
                style="margin-right: 8px"
                @click="handleReturnConfirmShow(button.taskKey)">退回到「{{button.taskName}}」
        </Button>
      </template>
      <Button @click="importProcessImage"
              v-if="tabValue==='processView'">导出流程图</Button>
      <Button v-if="type === 'toDo' && todoMultiExtend && todoMultiExtend.multiType === 'one_user_agree'"
              type="info"
              style="margin-right: 10px"
              @click="handleShortSaveShow"
              :loading="loading">暂存
      </Button>
      <Button v-if="type === 'toDo'"
              type="primary"
              @click="handleSubmitConfirmShow"
              :loading="loading">提交
      </Button>
      <Button v-if="type === 'done' && isCanCancel === 1"
              type="primary"
              @click="handleSubmitWithdrawnShow"
              :loading="loading">撤回流程
      </Button>
    </div>
    <!--  pdf 预览-->
    <DocumentPreview :visible="documentPreviewVisible"
                     :isFullScreen="true"
                     :documentSrc="documentSrc"
                     :id="`DocumentPreview${toDoId}`"
                     :projectId="proId"
                     :taskId="taskId"
                     :procInstId="procInstId"
                     @onCancel="documentPreviewVisible = false"></DocumentPreview>
    <VersionUpdate :visible="versionUpdateVisible"
                   @onOk="versionUpdateOk"
                   @onCancel="versionUpdateCancel"
                   :sourceId="versionHistorySourceId"
                   :projectId="proId"
                   :loading="versionUpdateLoading"
                   entry="2"
                   :versionUpdateObj="versionUpdateObj"></VersionUpdate>
    <!--entry 2 表示是流程文档库 -->
    <VersionEdit :visible.sync="versionEditVisible"
                 @onOk="versionEditOk"
                 :versionEditObj="versionEditObj"></VersionEdit>
    <VersionHistoryList :visible.sync="versionHistoryVisible"
                        :sourceId="versionHistorySourceId"
                        :projectId="proId"
                        entry="2"></VersionHistoryList>
    <!--entry 2 表示是流程文档库 -->
    <!-- 查看已配置表单文件 -->
    <config-form-list :visible="configFormVisible"
                      :projectId="proId"
                      :procInstId="procInstId"
                      @onCancel="configFormVisible = false">
    </config-form-list>
    <!-- 伦理上会投票 -->
    <ethical-vote :visible="voteVisible"
                  :toDoId="toDoId"
                  @finishVote="finishVote"
                  @onCancel="cancelVote">
    </ethical-vote>
    <ViewFile :visible="viewFileVisible"
              :projectId="proId"
              @onCancel="viewFileVisible=false">
    </ViewFile>
    <div v-show="isShowMask">
      <div class="mask-bg"></div>
      <div class="message-mask">
        <div class="mask-box">
          <div class="messag-img">
            <img v-show="isSuccess"
                 src="../../assets/common/message-success.png"
                 alt="成功">
            <img v-show="!isSuccess"
                 src="../../assets/common/message-fail.png"
                 alt="失败">
          </div>
          <div :class="isSuccess ? 'success-tip' : 'fail-tip'">{{tip}}</div>
        </div>
      </div>
    </div>
    <!--编辑批注-->
    <Annotation :visible.sync="annotationVisible"
                :procInstId="procInstId"
                :taskId="taskId"
                entry="edit"
                @onOk="handleNoteOk"
                @onCancel="annotationVisible=false"
                :annotationObj="annotationObj"></Annotation>

  </Modal>
</template>

<script>
import docType from '@/config/docType';
import api from '@/api/workbench/workbench';
import proApi from '@/api/project/process';
import processApi from '@/api/project/processDoc';
import History from '@/components/BusinessComponents/Process/History.vue';
import ViewDetail from '@/components/BusinessComponents/Process/ViewDetail.vue';
import designTemplate from '@/components/BusinessComponents/FormDesign/designTemplate.vue';
import DocumentPreview from '@/components/BusinessComponents/Document/DocumentPreview.vue';
import VersionHistoryList from '@/components/BusinessComponents/Document/VersionHistoryList.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { forEach } from 'lodash';
import NewestFileList from './components/NewestFileList.vue';
import ConfigFormList from './components/ConfigFormList.vue';
import EthicalVote from './components/EthicalVote.vue';
import VersionUpdate from './components/VersionUpdate.vue';
import VersionEdit from './components/VersionEdit.vue';
import VoteTable from './components/VoteTable.vue';
import Annotation from './components/Annotation.vue';
import ViewFile from './components/ViewFiles.vue';

const {
  getExtraInfo,
  refuse,
  withdrawal,
  apiProcessFormList,
  apiGetRoleUsers,
  apiGetVoteCode,
  approve,
  uploadFile,
  deleteFile,
  shortSave,
  apiGetProjec,
  getAnnotationList,
  deleteAnnotation,
  apiGetMyRoles,
} = api;
const { apiGetAllProcessHistory } = proApi;
const { versionNumberUpdate } = processApi;

export default {
  name: 'Approval',
  props: ['toDoId', 'visible', 'procInstId', 'procInstName', 'type', 'taskId', 'subProcStatus', 'projectId', 'agencyType'], // agencyType 用来区分是代办还是已办 1 表示已办
  components: {
    designTemplate,
    History,
    ViewDetail,
    DocumentPreview,
    VersionUpdate,
    VersionHistoryList,
    NewestFileList,
    ConfigFormList,
    EthicalVote,
    VoteTable,
    ViewFile,
    VersionEdit,
    Annotation,
  },
  data() {
    return {
      getNewDownloads: [],
      isRadio: '',
      funcData1: [],
      currentItemObj: {},
      isCanCancel: null,
      isLone: '0',
      docType,
      isCollapse: true, // 展开/折叠 左侧 本流程最新文件
      isRightCollapse: true, // 展开/折叠 右侧 流程表单等
      showFiles: true, // 展开/折叠查看已上传文件
      showRoleForms: true, // 展开/折叠 编辑角色及选择表单
      showUpload: true, // 展开/折叠上传文件
      showRemarks: true, // 展开/折叠 批注内容
      showForm: {}, // 展开/折叠 表单
      showRemark: true, // 展开/折叠审批意见
      showRoles: true, // 展开/折叠审批角色
      showConditions: true, // 展开/折叠通过条件
      loading: false,
      fullscreen: true,
      templateList: [],
      tabValue: '',
      leftTabValue: 'file',
      returnButtonList: [],
      returnTaskKey: '',
      configFormVisible: false,
      voteVisible: false, // 是否 显示 伦理投票 弹窗
      showVote: false, // 是否显示 伦理投票 按钮
      voteFinish: false, // 伦理投票 是否结束
      confirmVisible: false,
      remark: '',
      remarkList: [],
      uploadFileList: [],
      viewFileList: [],
      teamRoles: [],
      confirmLoading: false,
      signatureVisible: false,
      signaturePassword: '',
      signatureLoading: false,
      selectSignatureItem: null,
      signatureCode: '',
      userList: [],
      uploadProgressVisible: false,
      documentPreviewVisible: false,
      versionUpdateVisible: false,
      versionUpdateLoading: false,
      versionUpdateObj: {}, // 更新文件及版本
      versionEditObj: {}, // 版本修改
      versionEditVisible: false,
      proId: '',
      documentSrc: '',
      versionHistoryVisible: false,
      sendSysMessage: 1,
      sendSms: 0,
      sendEmail: 0,
      versionHistorySourceId: '',
      // 还原文件数据列表
      returnFileList: [],
      filesColumns: [
        {
          title: '名称',
          key: 'name',
          minWidth: 120,
          slot: 'fileName',
        },
        {
          title: '版本',
          key: 'version',
          slot: 'version',
          width: 100,
        },
        {
          title: '更新时间',
          key: 'versionTime',
          width: 110,
          render: (h, { row }) => h('span', row.versionTime || '--'),
        },
        {
          title: '状态',
          key: 'uploadStatus',
          slot: 'uploadStatus',
          width: 100,
        },
        {
          title: '操作',
          key: 'actions',
          slot: 'actions',
          // fixed: 'right',
          width: 240,
        },
      ],
      viewFilesColumns: [
        {
          title: '名称',
          key: 'name',
          minWidth: 120,
          slot: 'fileName',
        },
        {
          title: '版本',
          key: 'version',
          slot: 'version',
          width: 100,
        },
        {
          title: '更新时间',
          key: 'versionTime',
          render: (h, { row }) => h('span', row.versionTime || '--'),
          width: 110,
        },
        {
          title: '操作',
          key: 'actions',
          slot: 'actions',
          // fixed: 'right',
          width: 240,
        },
      ],
      remarkColumns: [
        // 流程审批意见汇总
        {
          title: '流程节点名称',
          key: 'taskName',
          minWidth: 100,
        },
        {
          title: '审核意见',
          key: 'remark',
          minWidth: 110,
          slot: 'remark',
        },
        {
          title: '操作',
          key: 'result',
          minWidth: 100,
        },
        {
          title: '操作人',
          key: 'dealedBy',
          minWidth: 100,
          slot: 'dealedBy',
        },
        {
          title: '操作日期',
          key: 'dealedTime',
          minWidth: 100,
        },
      ],
      historyList: [], // 流程审批意见汇总
      noteColumns: [ // 右侧 批注内容列表
        {
          title: '文件名称',
          key: 'docfileName',
          minWidth: 120,
        },
        {
          title: '文件版本',
          key: 'version',
          width: 100,
        },
        {
          title: '批注内容',
          key: 'annotation',
          slot: 'annotation',
          minWidth: 110,
        },
        {
          title: '批注人',
          key: 'operatorName',
          width: 110,
        },
        {
          title: '操作',
          key: 'actions',
          slot: 'actions',
          width: 140,
        },
      ],
      noteList: [], //  右侧 批注内容汇总
      annotationVisible: false,
      annotationObj: {},
      todoMultiExtend: null,
      multiTypeMap: {
        all_user_agree: '全部用户同意后通过',
        all_role_agree: '全部角色同意后通过',
        one_user_agree: '只要一个同意就通过',
      },
      annotationFileList: [],
      subProcessFormList: [],
      chooseFillForms: false, // 同一选择用户表单
      chooseUserFillForms: false, // 分别选择用户表单
      fillFormIds: [],
      tableLoading: false,
      isloaded: false,
      code: '',
      voteId: '',
      taskName: '',
      status: '', // 投票状态：进行中=1；已结束=2；已取消=3；
      voteData: [],
      viewFileVisible: false,
      showDocLibrary: false,
      sonUserForms: {},
      fileNameList: '', // 本流程最新文件名称拼接
      isSuccess: true, // 是否显示上传成功与失败图片，默认 true 显示成功图片
      isShowMaskTime: 2000, // 是否显示上传文件弹框，默认 2000 显示
      isShowMask: false, // 是否显示上传文件弹框，默认 false 不显示
      tip: '文件上传成功', // 上传文件提示
      timer: '',
      getprojects: '',
      projectName: '',
      sponsorLinkedName: '',
      croLinkedName: '',
      researcher: '',
      isSuper: false,
      selfRoles: [],
      signItemArr: [], // 获取隐藏的CA签名控件
    };
  },
  computed: {
    ...mapState('config', ['IS_ONLY_PDF', 'IS_FILE_REMIND', 'IS_EMAIL_NOTICE']),
    ...mapState({
      contentHeight: state => state.contentHeight - 110,
    }),
    userName() {
      return this.$store.state.user.name;
    },
    conHeight() {
      return this.fullscreen
        ? document.body.clientHeight - 220
        : this.contentHeight;
    },
    isZhongshan() {
      return this.$store.state.environment === 'zhongshan';
    },
    isXinhua() {
      return this.$store.state.environment === 'xinhua';
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.fullscreen = true;
        this.signItemArr = []; // 隐藏签名组件 为空
        this.sendSms = this.isXinhua ? 1 : 0;
        this.sendEmail = 0;
        this.proId = this.$route.params.projectId || this.projectId;
        this.getRoles(); // 获取自己在当前项目下的角色列表
        this.tabValue = (this.type === 'toDo' || this.type === 'done') ? 'form' : 'history';
        if (this.procInstId) {
          this.getProcessHistory(); // 获取流程审批意见
        }
        this.getNoteList(); // 获取批注列表
        this.getProgramInfo(); // 获取项目信息
        if (this.toDoId && this.type !== 'done') {
          this.getAllData('init');
        }
      }
    },
    remark(newval, oldval) {
      if (newval === '') {
        this.funcData1 = [];
      }
    },
  },
  mounted() {
    // 点击页面任意地方，隐藏状态弹层
    document.addEventListener('click', () => {
      this.isShowMask = false;
    });
  },
  methods: {
    // 获取自己在当前项目下的角色列表
    getRoles() {
      this.$asyncDo(async () => {
        const res = await apiGetMyRoles({
          projectId: this.proId,
        });
        if (res && res.data) {
          this.isSuper = !!(res.data.isSuper === 1);
          if (!this.isSuper) {
            this.selfRoles = res.data.roles || [];
          } else {
            this.selfRoles = [];
          }
        }
      });
    },
    // 根据角色权限 获取是否展示表单
    getShowStatus(permsRoles) {
      if (this.isSuper || !permsRoles || !permsRoles.length) return true;
      let status = false;
      for (let i = 0; i < permsRoles.length; i++) {
        if (this.selfRoles && this.selfRoles.includes(permsRoles[i])) {
          status = true;
          break;
        }
      }
      return status;
    },
    // 项目基本信息
    getProgramInfo() {
      this.$asyncDo(async () => {
        const params = {
          isDealAcronym: 0,
        };

        this.loading = true;

        const res = await apiGetProjec(params, this.proId);
        if (res) {
          this.getprojects = res.data.project;
          this.projectName = this.getprojects.name;
          this.sponsorLinkedName = this.getprojects.sponsorLinkedName;
          this.croLinkedName = this.getprojects.croLinkedName;
          this.researcher = this.getprojects.researcher;
        }
        this.loading = false;
      });
    },
    // 审批意见反显
    getfunc(data) {
      this.funcData1 = [...data];
      this.remarkList = [];
      this.remarkList = this.funcData1.map((dataItem) => {
        if (dataItem.name && dataItem.funcLabel) {
          return `${dataItem.name}：${dataItem.funcLabel}`;
        } if (dataItem.name && !dataItem.funcLabel) {
          return `${dataItem.name}`;
        } if (!dataItem.name && dataItem.funcLabel) {
          return `${dataItem.funcLabel}`;
        }
        return '';
      });
      this.remark = this.remarkList.length ? this.remarkList.join('；') : '';
    },
    getAllData(type) {
      this.funcData1 = [];
      this.remark = '';
      this.$asyncDo(async () => {
        this.loading = true;
        const res = await getExtraInfo({ id: this.toDoId });
        if (res && res.data) {
          if (type === 'init') {
            // type：file 只更新文件列表,不刷新其他数据；init 更新全部数据
            this.showVote = res.data.showVote; // 是否显示 伦理投票 按钮
            this.taskName = res.data.taskName; // 流程节点名称
            this.status = res.data.voteStatus; // 投票状态
            this.showDocLibrary = res.data.showDocLibrary; // 是否展示查看文件按钮
            if (this.status === 2) {
              // 投票状态：进行中=1；已结束=2；已取消=3；
              this.initVote();
            }
            // 是否选择子流程
            this.chooseFillForms = res.data.chooseFillForms;
            this.chooseUserFillForms = res.data.chooseUserFillForms;
            // 退回节点按钮列表
            this.returnButtonList = res.data.rollbackTasks;
            this.sonUserForms = {};
            this.fillFormIds = [];
            this.templateList = [];
            this.teamRoleUserMap = {};
            this.teamRoles = [];
            // 多人审批
            this.todoMultiExtend = res.data.todoMultiExtend;
            if (res.data.tempData) { // 暂存过数据
              // 暂存数据
              const saveData = JSON.parse(res.data.tempData);
              this.teamRoleUserMap = saveData.teamRoleUserMap || {};
              if (res.data.chooseUserFillForms) {
                this.sonUserForms = saveData.sonUserForms || {}; // 用户选择不同表单 存的数据
                // 编辑人员
                this.getTeamRoles(res.data.teamRoles, this.sonUserForms, 'more'); // 每个用户选择不同表单
              } else if (res.data.chooseFillForms) {
                this.fillFormIds = saveData.fillFormIds || []; // 用户统一选择表单
                // 编辑人员
                this.getTeamRoles(res.data.teamRoles, this.fillFormIds, 'one'); // 所有用户都是一样的表单
              }
              if (res.data.chooseFillForms || res.data.chooseUserFillForms) {
                this.getSubProcessFormList();
              }
              const arr = (saveData.preRemark && saveData.preRemark.length) ? saveData.preRemark.split('；').filter(item => item) : [];
              this.funcData1 = arr.map(item => ({
                funcLabel: item.split('：')[1] || '',
                name: item.split('：')[0] || '',
              }));
              this.templateList = saveData.formData.filter(form => this.getShowStatus(form.roles)).map((form) => {
                Object.assign(form, { json: JSON.parse(form.json) });
                return form;
              });
              this.templateList.forEach((form) => {
                this.$set(this.showForm, `form${form.id}`, true); // 默认展开所有表单
                if (form.json && form.json.length) {
                  form.json.forEach((item) => {
                    if (item.data && !item.json) {
                      if (typeof item.data === 'object') {
                        item.json = item.data;
                      } else {
                        item.json = JSON.parse(item.data);
                      }
                    }
                    if (
                      item.json
                      && (item.json.bindType === 'date'
                        || item.json.bindType === 'datetime')
                    ) {
                      item.json.dataValue = item.json.dataValue
                        ? new Date(item.json.dataValue)
                        : '';
                    } else if (item.json
                      && item.json.type === 'elec_sign') {
                      let arr = [];
                      if (item.json.dataValue) {
                        if (typeof item.json.dataValue === 'object') {
                          arr = item.json.dataValue;
                        } else {
                          arr = JSON.parse(item.json.dataValue);
                        }
                      }
                      item.json.dataValue = arr;
                      // 自动签名的CA组件，页面隐藏显示，提交时，后台自动调取签名接口保存。
                      if (item.json.signType === 1) {
                        this.signItemArr.push({
                          // ...item,
                          formDataId: form.id,
                          componentId: item.id,
                        });
                      }
                    }
                  });
                }
              });
            } else {
              // 初始化或者提交过的数据
              this.teamRoleUserMap = res.data.teamRoleUserMap || {};
              if (res.data.chooseUserFillForms) {
                this.sonUserForms = res.data.sonUserForms || {}; // 用户选择不同表单 存的数据
                // 编辑人员
                this.getTeamRoles(res.data.teamRoles, this.sonUserForms, 'more'); // 每个用户选择不同表单
              }
              if (res.data.chooseFillForms) {
                this.fillFormIds = res.data.fillFormIds || []; // 用户统一选择表单
                // 编辑人员
                this.getTeamRoles(res.data.teamRoles, this.fillFormIds, 'one'); // 所有用户都是一样的表单
              }
              if (res.data.chooseFillForms || res.data.chooseUserFillForms) {
                this.getSubProcessFormList();
              }
              const arr = (res.data.preRemark && res.data.preRemark.length) ? res.data.preRemark.split('；').filter(item => item) : [];
              this.funcData1 = arr.map(item => ({
                funcLabel: item.split('：')[1] || '',
                name: item.split('：')[0] || '',
              }));
              this.templateList = res.data.formDataList.filter(form => this.getShowStatus(form.roles)).map((form) => {
                Object.assign(form, { json: JSON.parse(form.json) });
                return form;
              });
              this.templateList.forEach((form, index) => {
                this.$set(this.showForm, `form${form.id}`, true); // 默认展开所有表单。
                if (form.json && form.json.length) {
                  form.json.forEach((item) => {
                    if (item.data && !item.json) {
                      if (typeof item.data === 'object') {
                        item.json = item.data;
                      } else {
                        item.json = JSON.parse(item.data);
                      }
                    }
                    if (
                      item.json
                      && (item.json.bindType === 'date'
                        || item.json.bindType === 'datetime')
                    ) {
                      item.json.dataValue = item.json.dataValue
                        ? new Date(item.json.dataValue)
                        : '';
                    }
                    // 自动签名的CA组件，页面隐藏显示，提交时，后台自动调取签名接口保存。
                    if (item.json && item.json.signType === 1) {
                      this.signItemArr.push({
                        // ...item,
                        formDataId: form.id,
                        componentId: item.id,
                      });
                    }
                  });
                }
              });
            }
            this.remarkList = this.funcData1.map((dataItem) => {
              if (dataItem.name && dataItem.funcLabel) {
                return `${dataItem.name}：${dataItem.funcLabel}`;
              } if (dataItem.name && !dataItem.funcLabel) {
                return `${dataItem.name}`;
              } if (!dataItem.name && dataItem.funcLabel) {
                return `${dataItem.funcLabel}`;
              }
              return '';
            });
            this.remark = this.remarkList.length ? this.remarkList.join('；') : '';
          }
          // 上传文件列表
          let uploadFileList = [];
          if (res.data.uploadFileForms.length) {
            const newUploadFileForms = res.data.uploadFileForms;
            uploadFileList = newUploadFileForms.filter(form => this.getShowStatus(form.roles)).map(fileGroup => ({
              ...fileGroup,
              hisFileList: fileGroup.hisFileList.filter(
                file => !file.isDel,
              ),
              hisFileDataList: fileGroup.hisFileList.filter(
                file => !file.isDel,
              ),
            }));
          }
          this.uploadFileList = uploadFileList;
          // 查看文件列表
          const viewFileList = [];
          if (res.data.viewFileForms.length) {
            const newViewFileForms = res.data.viewFileForms;
            newViewFileForms.forEach((fileGroup, fileGroupIndex) => {
              viewFileList[fileGroupIndex] = {
                ...fileGroup,
                hisFileList: fileGroup.hisFileList.filter(
                  file => !file.isDel,
                ),
                hisFileDataList: fileGroup.hisFileList.filter(
                  file => !file.isDel,
                ),
              };
            });
          }
          this.viewFileList = viewFileList;
        }
        this.loading = false;
      });
      // this.getProgramInfo();
    },
    // 获取流程审批历史
    getProcessHistory() {
      this.$asyncDo(async () => {
        this.loading = true;
        const res = await apiGetAllProcessHistory({ id: this.procInstId });
        if (res) {
          this.historyList = res.data;
        }
        this.loading = false;
      });
    },
    // 查看文件
    onViewDocument() {
      this.viewFileVisible = true;
    },
    handleCollapse(val, template) {
      const bool = !!val;
      this.$set(this.showForm, `form${template.id}`, !bool);
    },
    // 预览配置表单
    viewFormList() {
      this.configFormVisible = true;
    },
    // 伦理投票
    ethicalVoting() {
      this.voteVisible = true;
    },
    // 完成伦理投票
    finishVote() {
      this.initVote();
      this.voteVisible = false;
      this.voteFinish = true;
    },
    cancelVote() {
      this.voteVisible = false;
    },
    initVote() {
      this.$asyncDo(async () => {
        this.loading = true;
        const res = await apiGetVoteCode({
          todoId: this.toDoId,
        });
        if (res && res.data) {
          this.code = res.data.code;
          this.status = res.data.status;
          this.voteId = res.data.id;
          const newArr = [];
          const obj = res.data.resultInfo;
          for (const key in obj) {
            if (obj[key].length) {
              const nameArr = obj[key].map(item => item.name);
              const remarkArr = obj[key].map(item => item.remark);
              newArr.push({
                result: key,
                voteNum: obj[key].length,
                name: nameArr.join(','),
                remarkNum: `${remarkArr.length}条`,
                allData: obj[key],
              });
            } else {
              newArr.push({
                result: key,
                voteNum: 0,
                name: '--',
                remarkNum: 0,
                allData: [],
              });
            }
          }
          this.voteData = [...newArr];
        }
        this.isloaded = true;
        this.loading = false;
      });
    },
    // 编辑自己的文件批注 updateAnnotation
    handleEditNote(row) {
      this.annotationObj = {
        ...row,
      };
      this.annotationVisible = true;
    },
    // 删除自己的文件批注 deleteAnnotation
    deleteNote(row) {
      this.$Modal.confirm({
        title: '删除批注',
        content: '您确认删除此批注吗？',
        onOk: async () => {
          const res = await deleteAnnotation({
            projectId: this.proId,
            id: row.id,
            docfileId: row.docfileId,
          });
          if (res) {
            this.$Message.success('操作成功');
            this.getNoteList();
            this.$refs.newFilesComp.getProcessDocList();
          }
        },
      });
    },
    // 编辑批注 提交
    handleNoteOk() {
      this.getNoteList();
      this.annotationVisible = false;
    },
    // 导出流程图
    importProcessImage() {
      // this.$refs.processView.$refs.dagreG6.graph.downloadImage();
      // this.$refs.processView.$refs.dagreG6.graph.fitView(20); // 自适应屏幕
      this.$refs.processView.$refs.dagreG6.graph.zoomTo(0); // 缩放。
      this.$nextTick(() => {
        this.$refs.processView.$refs.dagreG6.graph.downloadFullImage();
      });
    },
    // 查看项目信息
    openNewWindow() {
      const url = `#/projectInfo/${this.proId}`;
      window.open(
        url,
        '_blank',
        'width=1220,height=600,left=50,top=50,scrollbars=yes,toolbar=no',
      );
    },
    // 获取用户列表
    getTeamRoles(teamRoles, fillFormIds, type) {
      teamRoles.forEach(async (item) => {
        let role = item;
        const res = await apiGetRoleUsers({
          roleId: item.roleId,
          projectId: this.proId,
        });
        if (res) {
          let uList = [];
          if (this.teamRoleUserMap[item.roleId]) {
            uList = item.userList
              .filter(user => this.teamRoleUserMap[item.roleId].find(id => id === user.id))
              .map(user => ({
                ...user,
                formIds:
                  type === 'more'
                    ? fillFormIds[user.id]
                      ? fillFormIds[user.id]
                      : []
                    : fillFormIds,
              }));
          } else {
            uList = item.userList.map(user => ({
              ...user,
              formIds:
                type === 'more'
                  ? fillFormIds[user.id]
                    ? fillFormIds[user.id]
                    : []
                  : fillFormIds,
            }));
          }
          role = {
            ...item,
            userList: uList,
            userIds: this.teamRoleUserMap[item.roleId]
              ? this.teamRoleUserMap[item.roleId]
              : item.userList.map(user => user.id),
            roleUserList: res.data.map(user => ({
              ...user,
              userId: String(user.userId),
            })),
          };
          this.teamRoles.push(role);
        }
      });
    },
    // 获取批注列表
    async getNoteList() {
      const res = await getAnnotationList({
        projectId: this.proId,
        procInstId: this.procInstId,
      });
      if (res) {
        this.noteList = res.data || [];
      }
    },
    // 获取流程列表
    async getSubProcessFormList() {
      const res = await apiProcessFormList({
        isBind: 1,
      });
      if (res) {
        this.subProcessFormList = res.data || [];
      }
    },
    // 取消
    onCancel() {
      this.remark = '';
      this.funcData1 = [];
      this.$emit('onCancel');
      this.fullscreen = false;
    },
    // 撤回流程
    handleSubmitWithdrawnShow() {
      this.confirmLoading = true;
      this.$Modal.confirm({
        title: '撤回流程',
        content: '是否要撤回此流程',
        loading: this.confirmLoading,
        onOk: () => this.handleSubmitWithdrawnSave(),
      });
    },
    handleSubmitWithdrawnSave() {
      this.confirmLoading = false;
      this.$asyncDo(async () => {
        this.loading = true;
        const res = await withdrawal(this.toDoId);
        if (res) {
          this.$Message.success('操作成功！');
          this.$emit('onOk');
        }
        this.$Modal.remove();
        this.loading = false;
      });
    },
    // 撤回流程按钮是否展示 是否可以主动撤回流程，0-不可以，1-可以
    handleHistory(val) {
      this.isCanCancel = Number(val);
    },
    // 暂存确认
    handleShortSaveShow() {
      this.confirmLoading = true;
      this.$Modal.confirm({
        title: '流程暂存确认',
        content: '您确定要暂存流程吗？',
        loading: this.confirmLoading,
        onOk: () => this.handleShortSave(),
      });
    },
    handleShortSave() {
      const formAllData = this.templateList.map((template) => {
        const { list, formData } = this.$refs[`designTemplate_${template.id}`][0];
        const newListArr = template.json.map((item) => {
          const newItem = list.find(li => li.id === item.id);
          if (newItem) {
            newItem.isShow = true;
            return newItem;
          }
          return { ...item, isShow: false };
        });
        const templateList = newListArr.map((listItem) => {
          let dataJson = {};
          if (typeof listItem.data === 'object') {
            dataJson = listItem.data;
          } else {
            dataJson = JSON.parse(listItem.data);
          }
          if (dataJson.bindType === 'date') {
            if (formData[dataJson.code]) {
              dataJson.dataValue = moment(formData[dataJson.code]).format(
                'YYYY-MM-DD',
              );
            } else {
              dataJson.dataValue = '';
            }
          } else if (dataJson.bindType === 'datetime') {
            if (formData[dataJson.code]) {
              dataJson.dataValue = moment(formData[dataJson.code]).format(
                'YYYY-MM-DD HH:mm:ss',
              );
            } else {
              dataJson.dataValue = '';
            }
          } else if (dataJson.type === 'elec_sign') { // 新华CA 电子签名
          } else if (dataJson.type === 'elec_sign') { // CA 电子签名
            dataJson.dataValue = [];
            if (formData[dataJson.code] && formData[dataJson.code].length) {
              const arr = formData[dataJson.code].map((item) => {
                if (item.todoId) {
                  return item;
                }
                return {
                  ...item,
                  todoId: this.toDoId,
                };
              });
              dataJson.dataValue = arr;
            }
          } else if (dataJson.type === 'base_Select' && dataJson.isRequest === '2') {
            dataJson.dataValue = dataJson.userName || '';
          } else {
            dataJson.dataValue = formData[dataJson.code];
          }
          if (
            listItem.json
            && (listItem.json.type === 'base_Radio'
              || listItem.json.type === 'base_Checkbox')
          ) {
            dataJson.optionList = listItem.json.optionList;
          }

          //  赋值
          const obj = {};
          Object.keys(listItem).forEach((key) => {
            if (key !== 'json') {
              obj[key] = listItem[key];
            }
          });
          obj.data = JSON.stringify(dataJson);
          return obj;
        });
        return {
          ...template,
          json: JSON.stringify(templateList),
          formDataId: template.id,
        };
      });
      // 编辑角色逻辑处理
      const teamRoleUserMap = {};
      for (let i = 0; i < this.teamRoles.length; i += 1) {
        teamRoleUserMap[this.teamRoles[i].roleId] = this.teamRoles[i].userIds;
      }
      const params = {
        formData: formAllData,
        teamRoleUserMap,
        remark: this.remark,
        sendSysMessage: this.sendSysMessage,
        sendSms: this.sendSms,
        sendEmail:
          this.type === 'toDo' && this.IS_EMAIL_NOTICE ? this.sendEmail : 0,
        todoMultiExtend: this.todoMultiExtend ? 1 : 0,
      };
      if (this.chooseUserFillForms) {
        // 用户选择不同表单
        params.sonUserForms = this.sonUserForms;
      } else {
        // 用户统一选择表单
        params.fillFormIds = this.fillFormIds;
      }
      // 提交暂存
      this.$asyncDo(async () => {
        this.loading = true;
        const submitRes = await shortSave(this.toDoId, {
          userId: this.$store.state.user.info.id,
          tempData: params,
        });
        if (submitRes) {
          this.$Message.success('操作成功！');
          this.confirmVisible = false;
          this.remark = '';
          this.$emit('onOk');
        }
        this.loading = false;
        this.$Modal.remove();
      });
      return false;
    },
    // 提交确认
    async handleSubmitConfirmShow(taskKey) {
      const promiseArr = this.templateList.map((template) => {
        const { $refs } = this.$refs[`designTemplate_${template.id}`][0];
        return $refs.designTemplateForm.validate();
      });
      const validateRes = await Promise.all(promiseArr);
      if (validateRes.includes(false)) {
        this.$Message.error('有必填项未填写');
        return;
      }
      this.returnTaskKey = taskKey;
      this.confirmLoading = true;
      this.$Modal.confirm({
        title: '流程提交确认',
        content: '您确定要提交流程吗？',
        loading: this.confirmLoading,
        onOk: () => this.handleSubmit(true),
      });
    },
    // 提交表单
    handleSubmit(isPass) {
      // 退回时审核意见必填
      if (!this.remark && !isPass) {
        this.$Message.error('退回时审核意见必填！');
        this.$Modal.remove();
        return false;
      }
      if (isPass && (this.chooseFillForms || this.chooseUserFillForms)) {
        let isEmpty = true;
        let isFormEmpty = false; // 用户选择不同表单时，是否有用户未选择表单。
        this.teamRoles.forEach((item) => {
          if (item.userIds.length) {
            isEmpty = false;
            if (
              this.chooseUserFillForms
              && item.userList
              && item.userList.length
            ) {
              // 用户选择不同表单
              for (let i = 0; i < item.userList.length; i++) {
                if (!item.userList[i].formIds.length) {
                  isFormEmpty = true;
                  break;
                }
              }
            }
          }
        });
        if (isEmpty) {
          this.$Message.error('编辑角色不能为空！');
          this.$Modal.remove();
          return false;
        }
        if (isFormEmpty) {
          // 用户选择不同表单
          this.$Message.error('用户表单不能为空！');
          this.$Modal.remove();
          return false;
        }
      }
      if (isPass && this.chooseFillForms) {
        // 用户统一选择表单
        if (!this.fillFormIds.length) {
          this.$Message.error('填写表单不能为空！');
          this.$Modal.remove();
          return false;
        }
      }
      const fd = new FormData();
      // 如果提交审批，则需要获取表单、上传文件、选人的数据
      if (isPass) {
        const formAllData = this.templateList.map((template) => {
          const { list, formData } = this.$refs[`designTemplate_${template.id}`][0];
          const newListArr = template.json.map((item) => {
            const newItem = list.find(li => li.id === item.id);
            if (newItem) {
              newItem.isShow = true;
              return newItem;
            }
            return { ...item, isShow: false };
          });
          const templateList = newListArr.map((listItem) => {
            let dataJson = {};
            if (typeof listItem.data === 'object') {
              dataJson = listItem.data;
            } else {
              dataJson = JSON.parse(listItem.data);
            }
            if (dataJson.bindType === 'date') {
              if (formData[dataJson.code]) {
                dataJson.dataValue = moment(formData[dataJson.code]).format(
                  'YYYY-MM-DD',
                );
              } else {
                dataJson.dataValue = '';
              }
            } else if (dataJson.bindType === 'datetime') {
              if (formData[dataJson.code]) {
                dataJson.dataValue = moment(formData[dataJson.code]).format(
                  'YYYY-MM-DD HH:mm:ss',
                );
              } else {
                dataJson.dataValue = '';
              }
            } else if (dataJson.type === 'elec_sign') { // 新华 深圳 CA 电子签名
            } else if (dataJson.type === 'elec_sign') { //   CA 电子签名
              dataJson.dataValue = [];
              if (formData[dataJson.code] && formData[dataJson.code].length) {
                const arr = formData[dataJson.code].map((item) => {
                  if (item.todoId) {
                    return item;
                  }
                  return {
                    ...item,
                    todoId: this.toDoId,
                  };
                });
                dataJson.dataValue = arr;
              }
            } else if (dataJson.type === 'base_Select' && dataJson.isRequest === '2') {
              dataJson.dataValue = dataJson.userName || '';
            } else {
              dataJson.dataValue = formData[dataJson.code];
            }
            if (
              listItem.json
              && (listItem.json.type === 'base_Radio'
                || listItem.json.type === 'base_Checkbox')
            ) {
              dataJson.optionList = listItem.json.optionList;
            }

            //  赋值
            const obj = {};
            Object.keys(listItem).forEach((key) => {
              if (key !== 'json') {
                obj[key] = listItem[key];
              }
            });
            obj.data = JSON.stringify(dataJson);
            return obj;
          });
          return {
            json: JSON.stringify(templateList),
            formDataId: template.id,
          };
        });
        fd.append('formData', JSON.stringify(formAllData));
        fd.append('elecSignHides', JSON.stringify(this.signItemArr));
        // 上传文件逻辑处理
        const uploadFiles = [];
        const viewFiles = [];
        for (
          let fileGroupIndex = 0;
          fileGroupIndex < this.uploadFileList.length;
          fileGroupIndex += 1
        ) {
          // 判断是否有必传文件没传
          const fileGroup = this.uploadFileList[fileGroupIndex];
          if (fileGroup.isRequired && !fileGroup.hisFileList.length) {
            this.$Message.error(`「${fileGroup.relateDocName}」必传!`);
            this.$Modal.remove();
            return false;
          }
        }
        // 查看文件提交处理
        for (
          let viewIndex = 0;
          viewIndex < this.viewFileList.length;
          viewIndex += 1
        ) {
          const viewFileGroup = this.viewFileList[viewIndex];
          if (viewFileGroup && viewFileGroup.hisFileDataList.length > 0) {
            viewFileGroup.hisFileDataList.forEach((item) => {
              if (item.id) {
                viewFiles.push(item.id);
              }
            });
          }
        }
        fd.append('fileIds', [...uploadFiles, ...viewFiles]);
        if (this.todoMultiExtend) {
          fd.append('todoMultiExtend', '1');
        }
        // 编辑角色逻辑处理
        const teamRoleUserMap = {};
        for (let i = 0; i < this.teamRoles.length; i += 1) {
          teamRoleUserMap[this.teamRoles[i].roleId] = this.teamRoles[i].userIds;
        }
        fd.append('teamRoleUserMap', JSON.stringify(teamRoleUserMap));
        // 提交备注
        fd.append('remark', this.remark);
        if (this.chooseUserFillForms) {
          // 用户选择不同表单
          fd.append('sonUserForms', JSON.stringify(this.sonUserForms));
        } else {
          // 用户统一选择表单
          fd.append('fillFormIds', this.fillFormIds);
        }
        fd.append('sendSysMessage', this.sendSysMessage);
        fd.append('sendSms', this.sendSms);
        fd.append(
          'sendEmail',
          this.type === 'toDo' && this.IS_EMAIL_NOTICE ? this.sendEmail : 0,
        );
      }

      // 同意审批
      if (isPass) {
        // 提交审批
        this.$asyncDo(async () => {
          this.loading = true;
          const submitRes = await approve(this.toDoId, fd);
          if (submitRes) {
            this.$Message.success('操作成功！');
            this.confirmVisible = false;
            this.remark = '';
            this.$emit('onOk');
          }
          this.loading = false;
          this.$Modal.remove();
        });
        return false;
      }
      this.$asyncDo(async () => {
        const fdRefuse = new FormData();
        const formDataRefuse = {
          id: this.toDoId,
          rollbackActId: this.returnTaskKey,
          remark: this.remark,
          todoMultiExtend: this.todoMultiExtend ? 1 : 0,
          sendSysMessage: this.sendSysMessage,
          sendSms: this.sendSms,
          sendEmail:
            this.type === 'toDo' && this.IS_EMAIL_NOTICE ? this.sendEmail : 0,
        };
        Object.keys(formDataRefuse).forEach((key) => {
          fdRefuse.append(key, formDataRefuse[key]);
        });
        // 拒绝审批
        const res = await refuse(fdRefuse, this.toDoId);
        if (res) {
          this.$Message.success('操作成功！');
          this.confirmVisible = false;
          this.remark = '';
          this.$emit('onOk');
        }
        this.loading = false;
        this.$Modal.remove();
      });
      return true;
    },
    // 回退确认
    handleReturnConfirmShow(taskKey) {
      this.returnTaskKey = taskKey;
      this.confirmLoading = true;
      this.$Modal.confirm({
        title: '流程退回确认',
        content: '是否进行返回上一节点操作(如进行此操作上一步骤将重新进行审批)',
        loading: this.confirmLoading,
        onOk: () => this.handleSubmit(false),
      });
    },
    // 上传文件
    handleUpload(file, index) {
      if (this.uploadFileList[index].fileNumLimit && this.uploadFileList[index].hisFileList.length >= this.uploadFileList[index].fileNumLimit) {
        // this.$Message.error(
        //   `文件夹文件上传限制数量为${this.uploadFileList[index].fileNumLimit}个,已超过上传限制`,
        // );
        this.isShowMask = true;
        this.closeUploadFileMask(false, 3000, `文件夹文件上传限制数量为${this.uploadFileList[index].fileNumLimit}个,已超过上传限制`);
        return false;
      }
      // 判断是否限制只能上传pdf格式
      if (this.IS_ONLY_PDF) {
        const nameArr = file.name.split('.');
        if (nameArr[nameArr.length - 1].toUpperCase() !== 'PDF') {
          // this.$Message.error('只能上传pdf格式的文件');
          this.isShowMask = true;
          this.closeUploadFileMask(false, 3000, '只能上传pdf格式的文件');
          return false;
        }
      }
      if (this.strCode(file.name) > 255) {
        this.$Message.error('文件名称过长，请限制在英文数字255字符以内，中文85字以内（含后缀名）！');
        return false;
      }
      const size = file.size / 1024;
      const filemaxsize = 1024 * 1024 * 10;// 10G
      if (size > filemaxsize) {
        this.$Message.error('附件大小不能大于10G');
        return false;
      }
      const fileMap = {
        name: file.name,
        createdTime: '--',
        versionTime: '',
        version: '1.0',
        uploadStatus: 0,
        file,
        index,
        annotation: '',
      };
      this.uploadFileList[index].hisFileList.push(file);
      this.uploadFileList[index].hisFileDataList.push(fileMap);
      const newFileList = this.uploadFileList[index].hisFileDataList.filter(
        item => !item.id,
      );
      this.versionUpdateObj = {};
      // 是否文件提交提醒
      if (this.IS_FILE_REMIND) {
        this.$Modal.confirm({
          title: '线下文件是否提交',
          okText: '是',
          cancelText: '否',
          onOk: () => {
            newFileList.forEach(fileItem => this.uploadFileProgress(fileItem));
          },
          onCancel: () => {
            newFileList.forEach(fileItem => this.uploadFileProgress(fileItem));
          },
        });
      } else {
        this.uploadFileProgress(fileMap);
      }
      return false;
    },
    async uploadFileProgress(item) {
      this.versionUpdateLoading = true;
      const fd = new FormData();
      fd.append('file', item.file);
      let fileMapping = {};
      if (this.versionUpdateObj.id) {
        fileMapping = {
          [this.uploadFileList[item.fileGroupIndex].relateDocName]: [
            {
              version: item.version,
              versionTime: item.versionTime,
              index: item.index,
              docfileId: item.docfileId || '',
            },
          ],
        };
      } else {
        fileMapping = {
          [this.uploadFileList[item.index].relateDocName]: [
            {
              version: item.version,
              versionTime: item.versionTime,
              index: item.index,
              docfileId: item.docfileId || '',
            },
          ],
        };
      }
      fd.append('fileMapping', JSON.stringify(fileMapping));
      const res = await uploadFile(
        {
          todoId: this.toDoId,
          fd,
        },
        e => this.onProgress(e, item),
      );
      if (res) {
        this.isShowMask = true;
        this.closeUploadFileMask(true, 1500, '文件上传成功');
        this.updateFileData(item, 1, res);
      } else {
        this.updateFileData(item, 2, res);
      }
    },
    updateFileData(item, status, res) {
      if (this.versionUpdateObj.id) {
        const updateObj = this.uploadFileList[item.fileGroupIndex].hisFileDataList[item.index];
        Object.assign(updateObj, {
          ...updateObj,
          id: '',
          name: item.file.name,
          version: item.version,
          versionTime: item.versionTime,
        });
      }
      Object.assign(item, {
        id: '',
        name: item.file.name,
        version: item.version,
        versionTime: item.versionTime,
      });
      this.$set(item, 'uploadStatus', status);
      if (res && res.data) {
        if (this.versionUpdateObj.id) {
          const updateObj = this.uploadFileList[item.fileGroupIndex].hisFileDataList[item.index];
          Object.assign(updateObj, {
            ...res.data,
          });
        }
        Object.assign(item, {
          ...res.data,
        });
        this.getAllData('file'); // 刷新文件列表
        this.versionUpdateVisible = false;
        this.versionUpdateLoading = false;
        this.$refs.newFilesComp.getProcessDocList(); // 刷新左侧 本流程最新文件
        this.getNoteList(); // 刷新右侧 批注内容 列表
      }
      if (res === false) { // 上传失败时，也要把loading取消
        this.getAllData('file'); // 刷新文件列表
        this.versionUpdateLoading = false;
      }
    },
    // 关闭上传文件弹框
    closeUploadFileMask(isSuccess, isShowMaskTime, tip) {
      this.isSuccess = isSuccess;
      this.tip = tip;
      this.timer = setTimeout(() => { this.isShowMask = false; }, isShowMaskTime);
      this.$once('hook:beforeDestroy', () => clearTimeout(this.timer));
    },
    onProgress(e, item) {
      let ot = new Date().getTime();
      let oloaded = 0;
      // 上传中回调
      const nt = new Date().getTime();
      const pertime = (nt - ot) / 1000;
      const perload = e.loaded - oloaded; // 计算该分段上传的文件大小，单位b
      ot = new Date().getTime();
      oloaded = e.loaded; // 重新赋值已上传文件大小，用以下次计算
      // 上传速度计算
      let speed = perload / pertime; // 单位b/s
      const bspeed = speed;
      let units = 'b/s'; // 单位名称
      if (speed / 1024 > 1) {
        speed /= 1024;
        units = 'k/s';
      }
      if (speed / 1024 > 1) {
        speed /= 1024;
        units = 'M/s';
      }
      speed = speed.toFixed(1);
      const percent = Math.round((e.loaded / e.total) * 100);
      this.updateFileData(item, `${percent}%`);
    },
    strCode(str) { // 获取字符串的字节数
      let count = 0; // 初始化字节数递加变量并获取字符串参数的字符个数
      if (str) { // 如果存在字符串，则执行
        const len = str.length;
        for (let i = 0; i < len; i++) { // 遍历字符串，枚举每个字符
          if (str.charCodeAt(i) > 255) { // 字符编码大于255，说明是双字节字符(即是中文),linux 汉字要算3个字符
            count += 3; // 则累加3个
          } else {
            count++; // 否则递加一次
          }
        }
        return count; // 返回字节数
      }
      return 0; // 如果参数为空，则返回0个
    },
    // 删除文件
    deleteFile(fileGroupIndex, fileIndex) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '确认删除该文件吗？',
        onOk: () => {
          const file = this.uploadFileList[fileGroupIndex].hisFileDataList[fileIndex];
          if (file.id) {
            this.onDelete(file.id, fileGroupIndex, fileIndex);
          } else {
            this.uploadFileList[fileGroupIndex].hisFileList.splice(fileIndex, 1);
            this.uploadFileList[fileGroupIndex].hisFileDataList.splice(fileIndex, 1);
            this.versionUpdateLoading = false;
          }
        },
      });
    },
    async onDelete(docfileId, fileGroupIndex, fileIndex) {
      const res = await deleteFile({
        todoId: this.toDoId,
        docfileId,
      });
      if (res) {
        this.$Message.success('删除成功！');
        this.uploadFileList[fileGroupIndex].hisFileList.splice(fileIndex, 1);
        this.uploadFileList[fileGroupIndex].hisFileDataList.splice(fileIndex, 1);
        this.$refs.newFilesComp.getProcessDocList();
        this.getNoteList(); // 刷新右侧 批注内容 列表
      }
      this.versionUpdateLoading = false;
    },
    // 选择人员
    handleRoleUserChange(value, index) {
      const item = Object.assign({}, this.teamRoles[index]);
      item.userIds = value;
      const newUserList = value.map(userId => item.roleUserList.find(user => user.userId === userId));
      const oldUserList = JSON.parse(JSON.stringify(item.userList));
      item.userList = newUserList.map((user) => {
        const oldItem = oldUserList.find(item => item.id === user.userId);
        if (oldItem) {
          return oldItem;
        }
        return {
          id: user.userId,
          name: user.userName,
          formIds: [],
        };
      });
      this.$set(this.teamRoles, index, item);
    },
    // 选择用戶表單
    handleRoleFormChange(value, index, idx) {
      this.sonUserForms[this.teamRoles[index].userList[idx].id] = value;
    },
    // 上传完成，关闭进度条
    closeUploadProgress(res) {
      if (res) {
        this.$Message.success('操作成功！');
        this.confirmVisible = false;
        this.remark = '';
        this.$emit('onOk');
      }
      this.loading = false;
      this.$Modal.remove();
      this.uploadProgressVisible = false;
    },
    handlePreview(row) {
      if (row.uploadStatus === 0 || row.uploadStatus === 2) {
        return;
      }
      const suffix = row.suffix ? row.suffix.toUpperCase() : '';
      if (docType.previewTypes.indexOf(suffix) > -1) {
        this.documentSrc = `${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/inline?token=${this.$store.state.user.token}`;
        this.documentPreviewVisible = true;
      } else {
        const downloadUrl = `${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`;
        window.open(downloadUrl);
      }
    },
    // 更新文件
    handleReplace(row, fileGroupIndex, index) {
      this.versionUpdateObj = {
        ...row,
        fileGroupIndex,
        index,
      };
      this.versionHistorySourceId = row.sourceId;
      this.versionUpdateVisible = true;
    },
    // 更新文件成功
    versionUpdateOk(file, fileObj) {
      if (file) { // 更新文件
        this.uploadFileProgress({ ...fileObj, file, docfileId: fileObj.id });
      } else { // 版本修改
        this.versionEditOk(fileObj);
      }
    },
    // 取消 更新文件
    versionUpdateCancel() {
      this.versionUpdateVisible = false;
      this.versionUpdateLoading = false;
    },
    // 版本修改
    handleVersionEdit(row, fileGroupIndex, index) {
      this.versionEditObj = {
        ...row,
        fileGroupIndex,
        index,
      };
      this.versionEditVisible = true;
    },
    // 版本修改成功
    versionEditOk(fileObj) {
      this.$asyncDo(async () => {
        this.versionUpdateLoading = true;
        const res = await versionNumberUpdate({
          docfileId:
          this.uploadFileList[fileObj.fileGroupIndex].hisFileDataList[
            fileObj.index
            ].id,
          projectId: this.proId,
          newVersion: fileObj.version,
        });
        if (res) {
          this.$Message.success('修改成功');
          this.$refs.newFilesComp.getProcessDocList(); // 刷新左侧 本流程最新文件
          this.getNoteList(); // 刷新右侧 批注内容 列表
          this.getAllData('file'); // 刷新文件列表
          const updateObj = this.uploadFileList[fileObj.fileGroupIndex].hisFileDataList[
            fileObj.index
            ];
          Object.assign(updateObj, {
            ...fileObj,
          });
          this.versionUpdateVisible = false;
          this.versionUpdateLoading = false;
        }
        if (res === false || (res && window.parseInt(res.code) !== 0)) { // 上传失败时，也要把loading取消
          this.versionUpdateLoading = false;
        }
      });
    },
    // 版本记录
    handleShowHistoryVersion({ sourceId }) {
      this.versionHistorySourceId = sourceId;
      this.versionHistoryVisible = true;
    },
    handleReturnFile(row, fileGroupIndex, index) {
      const fileData = this.returnFileList[row.returnIndex];
      this.uploadFileList[fileGroupIndex].hisFileList.splice(index, 1, fileData);
      this.uploadFileList[fileGroupIndex].hisFileDataList.splice(
        index,
        1,
        fileData,
      );
      this.returnFileList = this.returnFileList.filter(
        (file, i) => i !== row.returnIndex,
      );
    },
    copyText() {
      if (!this.$refs.newFilesComp.listData.length) {
        this.$Message.warning('暂无文件复制');
        return;
      }
      this.fileNameList = '';
      this.$refs.newFilesComp.listData.forEach((item) => {
        item.list.forEach((val) => {
          const nameT = this.getName(val.name);
          this.fileNameList += `${nameT} \r`;
        });
      });

      // 创建元素用于复制
      const aux = document.createElement('textarea');
      // 设置元素内容
      aux.value = this.fileNameList;
      // 将元素插入页面进行调用
      document.body.appendChild(aux);
      // 复制内容
      aux.select();
      // 将内容复制到剪贴板
      document.execCommand('copy');
      // 删除创建元素
      document.body.removeChild(aux);
      // 提示
      this.$Message.success('复制文件名称成功');
      // this.$Message.success(`复制文件名称成功：${ aux.value }`);
    },
    getName(file) {
      if (!file) return '';
      const fileName = file.substring(0, file.lastIndexOf('.'));
      return fileName;
    },
    getStatusStr(status) {
      let s = {
        text: '已上传',
        color: '',
      };
      switch (status) {
        case 0:
          s = {
            text: '未上传',
            color: '#ed4014',
          };
          break;
        case 1:
          s = {
            text: '已上传',
            color: '#19be6b',
          };
          break;
        case undefined:
          s = {
            text: '已上传',
            color: '#19be6b',
          };
          break;
        case 2:
          s = {
            text: '上传失败',
            color: '#ed4014',
          };
          break;
        default:
          s = {
            text: status,
            color: '',
          };
          break;
      }
      return s;
    },
    downloadNew() {
      if (JSON.stringify(this.getNewDownloads) === '{}') {
        this.$Message.warning('暂无数据可下载');
      } else {
        window.open(`${this.$baseUrl}/project/${this.proId}/docfile/${this.procInstId}/batch-download?token=${this.$store.state.user.token}`);
      }
    },
    getDownloads(data) {
      this.getNewDownloads = data || [];
    },
  },
};
</script>

<style lang="less">
.approval-modal {
  .addText {
    clear: both;
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 10px;
    .addTexts {
      margin-top: 5px;
    }
  }
  .modal-header {
    overflow: hidden;

    h2 {
      float: left;
    }

    .fullscreen {
      float: right;
      margin-right: 40px;
      text-decoration: none;
    }
    .iconfont {
      font-size: 20px;
    }
  }
  .custom-required {
    color: red;
  }

  .approve-card {
    .ivu-card-head,
    .ivu-card-body {
      padding: 0;
    }
    .ivu-tooltip {
      width: 100% !important;
      .over {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(~"100% - 320px");
      }
    }
  }

  .upload-file-wrapper {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    border-radius: 6px;

    .file-list-wrapper {
      padding: 10px;
    }

    .remark-wrapper {
      padding: 10px;
    }

    h2 {
      color: #17233d;
      background: #ececec;
      font-size: 18px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-bottom: 0;
      .warn {
        margin-left: 20px;
      }
    }
    .collapse-block {
      overflow: hidden;
      .collapse-icon {
        float: right;
        width: 40px;
        text-align: right;
        font-size: 26px;
        cursor: pointer;
        padding-right: 15px;
        .ivu-icon {
          color: #666;
        }
      }
      &.collapse-form {
        color: #fff;
        background: #2d8cf0;
        .ivu-icon {
          color: #fff;
        }
      }
    }
    .condition-content {
      padding: 10px;
    }

    .warn {
      color: #ff0101;
      font-weight: normal;
    }
  }
  .choose-wrap {
    line-height: 36px;
    padding: 5px 10px;
  }
  .notify-sty {
    margin-right: 16px;
    color: #2d8cf0;
  }
  .mask-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: 100;
  }
  .message-mask {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 416px;
    height: 215px;
    z-index: 1000;
    background: #ffffff;
    box-shadow: 0px 3px 24px 0px rgba(143, 146, 150, 0.38);
    border-radius: 6px;
    text-align: center;
    .mask-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -75px);
      font-size: 18px;
      font-weight: bold;
      .success-tip {
        color: #fd962e;
      }
      .fail-tip {
        color: #ff0000;
      }
    }
  }
}

.todo-approval-wrap {
  .left-card-wrap {
    .ivu-tabs-bar {
      padding-top: 20px;
    }
  }
  .collapse-arrow {
    position: absolute;
    right: 16px;
    top: 3px;
    font-size: 30px;
    z-index: 4;
    cursor: pointer;
  }
  .collapse-right-arrow {
    left: 15px;
    right: unset;
    top: 9px;
    font-size: 29px;
  }
  .collapse-after-arrow {
    position: unset;
    padding: 0;
    text-align: center;
  }
  /* 超出两行省略*/
  .ellipsis-text {
    width: 100%;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tooltip-class {
    .ivu-tooltip-rel {
      vertical-align: middle;
    }
  }
  .left-card {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
  }
  .ivu-card-head {
    padding: 0;
  }
  .ivu-card-extra {
    top: 9px;
  }
  .collapse-wrap {
    position: relative;
    .see-document {
      position: absolute;
      right: 60px;
      top: 13px;
      z-index: 5;
    }
  }
  .collapse-after {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    min-height: 700px;
  }
  .card-title {
    height: 34px;
    line-height: 34px;
    color: #17233d;
    font-size: 17px;
  }

  padding: 5px;

  h2 {
    font-weight: bold;
    border-bottom: solid 1px #e5e5e5;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 10px;

    button {
      position: absolute;
      right: 15px;
      top: -4px;
    }
  }

  table {
    width: 100% !important;
    margin: 0 !important;
    float: none !important;
    /*table-layout: fixed;*/
  }

  .no-form {
    text-align: center;
    padding-top: 100px;
    font-size: 16px;
    color: #666;
  }

  .form-content {
    width: 725px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow-x: auto;
    overflow-y: scroll;
    padding: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .component-error {
      background-color: rgba(255, 0, 0, 0.6);
    }
  }

  .history-content {
    padding: 0 auto 20px;
    overflow-x: auto;
    overflow-y: scroll;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .ivu-collapse-content {
      padding: 0;
    }
  }

  .approval-file-list {
    font-size: 14px;
    list-style: none;
    margin-bottom: 8px;

    .file-name {
      margin-right: 8px;
    }

    .file-delete:hover {
      text-decoration: underline;
    }
  }

  .approval-content-wrap {
    padding: 5px;
  }

  .upload-form {
    border: solid 1px #eee;
    padding: 5px;
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .file-upload-title {
      line-height: 1.5;
      color: #17233d;
      font-size: 15px;
      position: relative;
      padding: 10px;

      .ivu-tooltip {
        padding-right: 130px;
      }
    }

    .file-upload-btn {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  /*重置审批的表格的样式*/

  .html-content,
  .form-content {
    color: #000;

    p {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea {
      -webkit-appearance: textfield;
    }
  }
}
</style>

