<template>
	<Modal
		class="approval-modal document-body"
		:value="visible"
		:fullscreen="fullscreen"
		:scrollable="true"
		:styles="{ top: fullscreen ? 0 : '20px', width: '80%' }"
		@on-cancel="onCancel"
	>
		<div slot="header" class="modal-header">
			<h2>最新版本文件</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div slot="footer">
			<Button @click="onCancel">关闭</Button>
		</div>
		<file-list v-if="visible" :proId="projectId" type="latestFiles"></file-list>
	</Modal>
</template>
<script>
import FileList from "@/views/Project/processDoc/fileList.vue"

export default {
	props: ["visible", "projectId"],
	components: {
		FileList
	},
	data() {
		return {
			fullscreen: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		}
	}
}
</script>
