var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process-history-wrap"},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),(!_vm.historyList.length)?_c('div',{staticStyle:{"text-align":"center","height":"200px","line-height":"200px"}},[_c('span',[_vm._v("暂无记录")])]):_c('Collapse',{attrs:{"accordion":""},on:{"on-change":val => _vm.getHistoryForm(val)},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},_vm._l((_vm.historyList),function(item,index){return _c('Panel',{key:'history_' + index,class:{
				currentNode: item.status === 1,
				'nav-link': item.canOpen === 0
			},attrs:{"name":'history_' + index}},[_c('span',{staticClass:"task-name",attrs:{"title":item.taskName}},[_vm._v(_vm._s(item.taskName)+"：")]),_c('span',[_vm._v(_vm._s(item.dealedTime))]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.dealedBy))]),_c('div',{staticStyle:{"position":"relative","padding":"0 24px"},attrs:{"slot":"content"},slot:"content"},[(_vm.formLoading)?_c('Spin',{staticClass:"panel-spin"}):_vm._e(),_c('div',{staticClass:"history-remark"},[_vm._v("审批意见："+_vm._s(item.remark || "--"))]),(item.annotations && item.annotations.length)?_c('div',{staticClass:"upload-file-wrapper",staticStyle:{"margin-top":"10px","width":"725px"}},[_c('h2',{staticClass:"collapse-block"},[_vm._v("批注内容")]),_c('CtmsDataGrid',{attrs:{"tooltip-theme":'light',"columns":_vm.noteColumns,"data":item.annotations,"page":false,"showIndex":false},scopedSlots:_vm._u([{key:"annotation",fn:function({ row }){return _c('div',{},[_c('Tooltip',{staticClass:"tooltip-class",attrs:{"content":row.annotation,"theme":"light","max-width":"300","transfer":true}},[_c('p',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(row.annotation))])])],1)}}],null,true)})],1):_vm._e(),(
						(item.formList && item.formList.length) ||
						(item.formFiles && item.formFiles.length)
					)?_c('div',{staticStyle:{"margin-top":"16px"}},[_vm._l((item.formFiles),function(directory,directoryIndex){return _c('div',{key:directoryIndex,staticClass:"file-content"},[_c('h3',[(directory.directoryName.split('*').length > 1)?_c('span',{staticStyle:{"color":"red","margin-right":"3px"}},[_vm._v("*")]):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(directory.directoryName.split("*").length > 1
									? directory.directoryName.split("*")[1]
									: directory.directoryName)+"\n\t\t\t\t\t\t")]),(directory.list.length)?_c('ul',_vm._l((directory.list),function(file,fileIndex){return _c('li',{key:fileIndex,staticClass:"approval-file-list"},[_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(file.name))]),_c('a',{staticClass:"file-preview",on:{"click":function($event){return _vm.previewFile(directoryIndex, fileIndex)}}},[_vm._v("查看")])])}),0):_c('div',{staticStyle:{"padding":"0 16px","font-weight":"bold"}},[_vm._v("N/A")])])}),_vm._l((item.formList),function(form){return _c('div',{key:form.id,staticStyle:{"margin-bottom":"10px","border":"1px solid #ddd"}},[_c('h2',{staticClass:"form-name"},[_vm._v(_vm._s(form.name))]),_c('designTemplate',{attrs:{"is-no-height":true,"componentList":form.json,"showType":"history"}})],1)})],2):(!item.remark)?_c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("暂无记录")])]):_vm._e(),(item.changeText)?_c('div',{staticClass:"history-remark"},[_c('h2',[_vm._v("编辑团队成员：")]),_vm._l((_vm.getTeamRoleUser(item.changeText)),function(role){return _c('div',{key:role.roleId},[_c('h4',[_vm._v(_vm._s(role.roleName))]),_c('p',[_vm._v("\n\t\t\t\t\t\t\t修改前："+_vm._s(role.before.map(user => user.userName).join(","))+"\n\t\t\t\t\t\t")]),_c('p',[_vm._v("\n\t\t\t\t\t\t\t修改后："+_vm._s(role.after.map(user => user.userName).join(","))+"\n\t\t\t\t\t\t")])])})],2):_vm._e(),(item.subProcessForms && item.subProcessForms.length)?_c('WorkSheet',{attrs:{"workSheetList":item.subProcessForms,"index":index},on:{"formPrev":_vm.formPrev}}):_vm._e()],1)])}),1),_c('DocumentPreview',{attrs:{"visible":_vm.documentPreviewVisible,"isFullScreen":true,"documentSrc":_vm.documentSrc,"id":_vm.getRandomId},on:{"onCancel":function($event){_vm.documentPreviewVisible = false}}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }