<template>
	<!--预览配置表单-->
	<Modal
		class="config-form-list-modal"
		:value="visible"
		width="1150"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>配置表单列表</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<CtmsDataGrid
			:height="gridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import { mapState } from "vuex"
import { paramsStringify } from "@/utils/util"

const { getFormList } = api

export default {
	name: "configFormList",
	props: ["visible", "projectId", "procInstId"],
	data() {
		return {
			loading: false,
			fullscreen: false,
			listData: [],
			columns: [
				{
					title: "文件名",
					key: "name",
					nowrap: true
				},
				{
					title: "创建时间",
					key: "createdTime"
				},
				{
					title: "状态",
					key: "isDel",
					render: (h, params) =>
						h("span", {}, params.row.isDel ? "禁用" : "启用")
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					renderButton: ({ row }) => {
						const actionArr = [
							{
								label: "预览",
								on: {
									click: this.previewForm
								}
							}
							/* {
                label: '下载',
                on: {
                  click: this.download,
                },
              }, */
						]
						return actionArr.filter(item => !!item)
					}
				}
			]
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 100
				: this.dataGridHeight
		}
	},
	methods: {
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getFormList({
					procInstId: this.procInstId,
					projectId: this.projectId,
					includeSubProcess: 0 // 是否获取子流程 0 不要 ；
				})
				if (res) {
					this.listData = res.data || []
				}
				this.loading = false
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		// 预览表单信息 pdf
		previewForm({ row }) {
			window.open(
				`${this.$baseUrl}/common/form-file/${row.id}/filled?${paramsStringify({
					id: row.id,
					procInstId: this.procInstId, // 流程实例id
					projectId: this.projectId,
					token: this.$store.state.user.token
				})}`
			)
		},
		// 下载表单信息 pdf
		download({ row: { id } }) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${id}?token=${this.$store.state.user.token}`
			)
		}
	}
}
</script>

<style lang="less">
.config-form-list-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
