<template>
	<div
		class="new-file-list-wrapper"
		:style="{ height: conHeight ? conHeight - 80 + 'px' : 'auto' }"
	>
		<Spin fix v-if="loading"></Spin>
		<template v-if="listData.length">
			<div v-for="(item, index) in listData" :key="index" class="new-file-list">
				<div class="document-name">{{ item.name }}</div>
				<CtmsDataGrid
					:columns="newColumns"
					:data="item.list"
					:page="false"
					:showIndex="false"
				>
					<div slot-scope="{ row }" slot="annotation">
						<template v-if="row.id">
							<span :style="`color:${row.annotationStatus ? '#2D8cF0' : ''}`">
								{{ row.annotationStatus ? "已批注" : "未批注" }}
							</span>
						</template>
					</div>
					<div slot-scope="{ row }" slot="name">
						<Tooltip
							:content="row.name"
							placement="top-start"
							transfer
							:maxWidth="200"
						>
							<p class="text-two-wrap" @click="e => handlePreview(e, row)">
								{{ row.name }}
							</p>
						</Tooltip>
					</div>
				</CtmsDataGrid>
			</div>
		</template>
		<template v-else>
			<div class="no-file">暂无文件</div>
		</template>
		<!--表单预览-->
		<Preview
			:visible="previewVisible"
			type="document"
			@onCancel="previewVisible = false"
			:id="previewId"
		></Preview>
		<!--  pdf 预览-->
		<DocumentPreview
			:visible="newesFileVisible"
			:documentSrc="documentSrc"
			:row="row"
			:procInstId="procInstId"
			:taskId="taskId"
			:isFullScreen="true"
			:id="getRandomId"
			@onCancel="newesFileVisible = false"
		></DocumentPreview>
		<Annotation
			:visible.sync="annotationVisible"
			:procInstId="procInstId"
			:taskId="taskId"
			@onOk="onOk"
			@onCancel="handleCancelAnno"
			:annotationObj="annotationObj"
		></Annotation>
	</div>
</template>
<script>
import api from "@/api/workbench/workbench"
import processDocApi from "@/api/project/fileList"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import Preview from "@/views/Setting/Form/Preview.vue"
import moment from "moment"
import Annotation from "./Annotation.vue"

const { apiEnable } = api
const { getFileList } = processDocApi
export default {
	components: {
		DocumentPreview,
		Preview,
		Annotation
	},
	props: {
		conHeight: {},
		projectId: {},
		procInstId: {},
		procInstName: {},
		docType: {},
		entry: {},
		taskId: {},
		apiUrl: {
			default: () => ({ getFileList })
		}
	},
	data() {
		return {
			listData: [],
			newColumns: [],
			columns: [
				{
					title: "名称",
					key: "name",
					minWidth: 120,
					slot: "name"
				},
				{
					title: "版本",
					key: "version",
					width: 100,
					tooltip: true,
					align: "center",
					render: (h, { row }) => h("span", row.version || "--")
				},
				{
					title: "更新时间",
					key: "versionTime",
					width: 120,
					render: (h, { row }) => h("span", row.versionTime || "--")
				},
				{
					title: "批注状态",
					key: "annotation",
					slot: "annotation",
					width: 100
				}
			],
			actionColums: [
				{
					title: "操作",
					key: "action",
					width: 110,
					fixed: "right",
					align: "center",
					renderButton: params => {
						const btnList = [
							{
								label: "下载",
								on: {
									click: () => {
										let downloadUrl = ""
										if (this.entry === "ethicalMeeting") {
											// 伦理上会
											downloadUrl = `${this.$baseUrl}/ethic/proc/file-download?docfileId=${params.row.id}&token=${this.$store.state.user.token}`
										} else {
											downloadUrl = `${this.$baseUrl}/project/${this.projectId}/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										}
										window.open(downloadUrl)
									}
								}
							},
							{
								label: "批注",
								on: {
									click: this.handleAnnotation
								}
							},
							{
								label: params.row.reviewStatus !== 1 ? "审查通过" : "取消通过",
								on: {
									click: this.handleCheck
								},
								confirm: {
									title: `您确认${params.row.reviewStatus !== 1 ? "审查通过" : "取消通过"}这条数据吗？`
								}
							}
						]
						if (!params.row.suffix) {
							btnList[0] = null
						}
						if (this.entry === "ethicalMeeting") {
							btnList[1] = null
						}
						if (!(this.isXinhua && params.row.reviewStatusBtn)) {
							btnList[2] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			newesFileVisible: false,
			previewVisible: false,
			documentSrc: "",
			previewId: "",
			annotationObj: {},
			annotationVisible: false,
			row: {},
			loading: false
		}
	},
	computed: {
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		},
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		this.newColumns = [...this.columns, ...this.actionColums]
		if (this.entry === "ethicalMeeting") {
			// 从伦理上会页面进入的，不展示批注
			this.newColumns.splice(1, 3)
		}
	},
	mounted() {
		this.getProcessDocList("init")
	},
	methods: {
		// 获取最新文件
		async getProcessDocList(type) {
			this.loading = true
			const res = await this.apiUrl.getFileList(this.projectId, {
				// projectId: this.projectId,
				procInstId: this.procInstId
			})
			if (res) {
				this.$emit("getDownload", res.data)
				this.listData = []
				Object.keys(res.data).forEach(key => {
					this.listData.push({
						name: key,
						list: res.data[key]
					})
				})

				if (this.listData.length && type === "init" && this.isXinhua) {
					// 医院，且是伦理秘书和伦理总负责人的展示审查状态列，其余，不展示）
					if (
						this.listData[0].list.length &&
						this.listData[0].list[0].reviewStatusBtn
					) {
						const cols = [
							{
								title: "审查状态",
								key: "reviewStatus",
								width: 100,
								render: (h, { row }) =>
									h("span", row.reviewStatus === 1 ? "审查通过" : "--")
							}
						]
						this.newColumns = [...this.columns, ...cols, ...this.actionColums]
						if (this.entry === "ethicalMeeting") {
							// 从伦理上会页面进入的，不展示批注
							this.newColumns.splice(1, 3)
						}
					}
				}
			}
			this.loading = false
		},
		handlePreview(e, row) {
			if (row.type === 3) {
				// 表单预览
				this.previewId = row.entityId
				this.previewVisible = true
			} else {
				const suffix = row.suffix ? row.suffix.toUpperCase() : ""
				if (this.docType.previewTypes.indexOf(suffix) > -1) {
					this.documentSrc = `${this.$baseUrl}/document/docfile/${row.id}/inline?token=${this.$store.state.user.token}`
					this.row = row
					this.newesFileVisible = true
				} else {
					let downloadUrl = ""
					if (this.entry === "ethicalMeeting") {
						// 伦理上会
						downloadUrl = `${this.$baseUrl}/ethic/proc/file-download?docfileId=${row.id}&token=${this.$store.state.user.token}`
					} else {
						downloadUrl = `${this.$baseUrl}/project/${this.projectId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`
					}
					window.open(downloadUrl)
				}
			}
		},
		// 进行批注 新增批注
		handleAnnotation({ row }) {
			this.annotationObj = {
				...row,
				annotation: ""
			}
			this.annotationVisible = true
		},
		// 审查通过/ 取消通过
		handleCheck({ row }) {
			this.$asyncDo(async () => {
				const res = await apiEnable({
					projectId: this.projectId,
					procInstName: this.procInstName,
					docfileId: row.id,
					enable: row.reviewStatus !== 1 ? 1 : 0
				})
				if (res) {
					this.getProcessDocList()
				}
			})
		},
		// 批注完成
		onOk() {
			this.getProcessDocList()
			this.$emit("getNote") // 刷新右侧批注列表
			this.annotationVisible = false
		},
		handleCancelAnno() {
			this.annotationVisible = false
		}
	}
}
</script>
<style lang="less">
.new-file-list-wrapper {
	position: relative;
	overflow: auto;
	.new-file-list {
		.document-name {
			line-height: 1.5;
			color: #17233d;
			font-size: 15px;
			position: relative;
			padding: 10px;
		}
		.text-two-wrap {
			cursor: pointer;
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
	.no-file {
		text-align: center;
		margin: 30px 20%;
		font-size: 14px;
		line-height: 40px;
		border-bottom: 1px solid #d9d9d9;
	}
}
</style>
